import { Component, OnInit } from '@angular/core';
import { CtoContratoInformacionVigenteEncabezadoDTO } from '../../contratacion-dto/contrato-informacion-vigente-encabezado';
import { ContratacionService } from '../../contratacion.service';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EnumCtoEstadoContrato } from '../../constantes/contratacion-constantes';
import { EnumFechaFormato, EnumRegex, EnumRespuestaServicio, EnumTrnConstante } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { TrnConstantesService } from 'src/app/comun/constantes/trn-constantes.service';

@Component({
  selector: 'app-contratos-novedades',
  templateUrl: './contratos-novedades.component.html',
  styles: []
})
export class ContratosNovedadesComponent implements OnInit {

  contratosNovedades: CtoContratoInformacionVigenteEncabezadoDTO[];
  totalRecords: any;
  blockedDocument = false;

  // Filtros
  buscarFiltro: string;

  mapConstantes;
  fechaActual;
  diasLimiteModificaNovedades = 0;

  constructor(
    private datepipe: DatePipe,
    private messageService: MessageService,
    private router: Router,
    private contratacionService: ContratacionService,
    private constantesService: TrnConstantesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.blockedDocument = false;
    this.fechaActual = new Date();
    this.mapConstantes = new Map();
    this.cargarConstantesNovedades();
    this.contratosNovedades = [];
  }

  loadDataLazy(event: LazyLoadEvent) {
    this.blockedDocument = true;
    this.contratosNovedades = [];
    this.cargarContratos(event.first, event.rows, 'Id', 2);
  }

  buscar() {
    const filaDesde = 0;
    const cantidadRegistros = 20;
    this.blockedDocument = true;
    this.cargarContratos(filaDesde, cantidadRegistros, 'Id', 2);
  }

  limpiarFiltros() {
    this.buscarFiltro = null;
    this.buscar();
  }

  cargarContratos(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.blockedDocument = true;
    this.contratacionService.srvConsultarContratosInformacionVigente(null, null, null, null, null, null, null, filaDesde, cantidadRegistros, this.buscarFiltro, campoOrden, ascDesc)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.contratosNovedades = result.contratosInformacionVigente;
              this.contratacionService.srvCalcularEstadoVigenciaContrato(this.contratosNovedades);
              this.calcularPuedeGestionarNovedades(this.contratosNovedades);
              this.totalRecords = this.contratosNovedades.length == 0 ? 0 : this.contratosNovedades[0].totalRegistros;
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }


  /**
   * Metodo que llama al servicio de constantes para validar los días que se permite realizar las novedades contractuales despues de la fecha de terminación de un contrato
   */
  cargarConstantesNovedades() {
    const nombresConstantes = `${EnumTrnConstante.CTO_NOVEDADES_CONTRACTUALES_DIAS_HABILITA_GESTION_NOVEDADES}`;
    this.constantesService.srvGetConstantesListaPorNombre(nombresConstantes)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.mapConstantes = new Map();
              result.constantesLista.forEach(cns => {
                this.mapConstantes.set(cns.nombre, cns.valor);
              });
              //Obtiene el valor de la constante requerida
              this.diasLimiteModificaNovedades = Number(this.mapConstantes.get(EnumTrnConstante.CTO_NOVEDADES_CONTRACTUALES_DIAS_HABILITA_GESTION_NOVEDADES));
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
  }

  /**
   * Metodo que habilita el botón de gestionar novedades manipulando la variable habilitaGestionNovedadesContrato, teniendo en cuenta la fecha de terminacióñ del contato + dias adicionales vs la fecha actual
   * @param listaContratos Listado de contratos a validar
   * @returns Listado de contratos 
   */
  calcularPuedeGestionarNovedades(listaContratos: CtoContratoInformacionVigenteEncabezadoDTO[]) {
    // const days = Number(this.mapConstantes.get(EnumTrnConstante.CTO_NOVEDADES_CONTRACTUALES_DIAS_HABILITA_GESTION_NOVEDADES));
    const fechaActualString = this.datepipe.transform(this.fechaActual, EnumFechaFormato.YYYY_MM_DD_GUION);

    return listaContratos.forEach(cto => {
      cto.fechaTerminacionContrato = new Date(cto.fechaTerminacionContrato);

      const fechaTerminacionContratoMasDias = this.datepipe.transform(this.addDays(cto.fechaTerminacionContrato, this.diasLimiteModificaNovedades), EnumFechaFormato.YYYY_MM_DD_GUION);
      if (fechaTerminacionContratoMasDias >= fechaActualString) {
        cto.habilitaGestionNovedadesContrato = true;
      } else {
        cto.habilitaGestionNovedadesContrato = false;
      }
    });
  }

  /**
   * Metodo que agrega días a una fecha
   * @param date Fecha
   * @param days Cantidad días
   * @returns Fecha con los días adicionados
   */
  addDays(date: Date, days: number): Date {
    // const result = new Date(date);
    // result.setDate(result.getDate() + days);

    const fechaResultado: Date = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
    return fechaResultado;
  }


  /**
   * Metodo que resta días a una fecha
   * @param date Fecha
   * @param days Cantidad días
   * @returns Fecha con los días adicionados
   */
  subtractDays(date: Date, days: number): Date {
    const result = new Date(date);
    const milisegundosPorDia: number = 24 * 60 * 60 * 1000;
    return new Date(result.getTime() - days * milisegundosPorDia);
  }


  irNovedadesContrato(contrato: CtoContratoInformacionVigenteEncabezadoDTO) {
    this.router.navigate([this.router.url + '/novedadesContrato', contrato.id]);
  }

  get EnumCtoEstadoContrato() {
    return EnumCtoEstadoContrato;
  }

  get EnumTrnConstante() {
    return EnumTrnConstante;
  }

  get EnumRegex() {
    return EnumRegex;
  }
}
