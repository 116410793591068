import { Component, OnInit } from '@angular/core';
import { InformeContratistaBaseComponent } from '../informe-contratista-base/informe-contratista-base.component';
import { InformeContratistaService } from '../informe-contratista.service';
import { forkJoin } from 'rxjs';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { EnumRespuestaServicio } from '../../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumCtoTipoAnexoInforme } from 'src/app/contratacion/constantes/contratacion-constantes';
import { CtoAnexoInformeContratistaDTO } from '../../../../contratacion-dto/anexo-informe-contratista';
import { InventarioEquiposService } from 'src/app/inventario-equipos/inventario-equipos.service';
import { VwInvBienAniYpersonalDTO } from 'src/app/inventario-equipos/inventario-equipos.dto';
import { EnumInvInventario } from 'src/app/inventario-equipos/inventario-constantes';
import { CtoInformeContratistaDTO } from 'src/app/contratacion/contratacion-dto/informe-contratista';


@Component({
  selector: 'app-informe-contratista-paz-salvo',
  templateUrl: './informe-contratista-paz-salvo.component.html',
  styles: []
})
export class InformeContratistaPazSalvoComponent implements OnInit {

  informeContratista: CtoInformeContratistaDTO = {};
  // validarAnexo014_081: boolean = null;

  visibleDialogoSubirAnexo = false;
  visibleDialogoSubirGADF_F_014 = false;
  // visibleDialogoSubirGADF_F_081 = false;
  visibleDialogoVerAdjuntos = false;
  visibleDialogoEliminarAdjunto = false;
  visibleDialogoEliminarAnexo = false;

  adjuntosAnexosList: CtoAdjuntoInformeBorradorDTO[] = [];

  adjuntosComponente: any = [];
  rutaCloudStorageAnexos = "";
  nombreTipoAnexo = "";
  anexoInformeContratista: CtoAnexoInformeContratistaDTO;
  anexosAdicionalesList: CtoAnexoInformeContratistaDTO[]

  cantidadMaximaAdjuntos = 3;
  idAdjuntoEliminarAnexo = null;

  idAnexoGADF_F_014: number;
  idAnexoEliminar: number;
  // idAnexoGADF_F_081: number;

  //Bienes Asignados
  bienesAniPersonal: VwInvBienAniYpersonalDTO[];


  constructor(
    public lineaBase: InformeContratistaBaseComponent,
    private informeContratistaService: InformeContratistaService,
    private inventarioEquiposService: InventarioEquiposService,
    private principalComponent: PrincipalComponent
  ) { }


  ngOnInit() {
    this.rutaCloudStorageAnexos = this.lineaBase.vigencia + '/' + this.lineaBase.idContrato + '_' + this.lineaBase.numeroContrato + '/' + this.lineaBase.numeroDocumento + '/' + this.lineaBase.numeroPago + '/anexos/';
    this.adjuntosComponente = [];
    this.adjuntosAnexosList = [];
    this.anexoInformeContratista = null;

    this.cargarAnexos(this.lineaBase.idInforme, this.lineaBase.esBorrador);
    this.cargarInventarioAsignado();
  }

  cargarAnexos(idInforme, esBorrador) {
    this.informeContratistaService.srvGetAnexosPorSubTipoAnexoInforme(EnumCtoTipoAnexoInforme.SUBTIPO_PAZ_SALVO, idInforme, esBorrador).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.anexosAdicionalesList = result.anexos;
            // this.validarAnexo014_081 = null;
            this.idAnexoGADF_F_014 = undefined;
            // this.idAnexoGADF_F_081 = undefined;
            this.anexosAdicionalesList.forEach(element => {
              // if (element.idTipoAnexo == EnumCtoTipoAnexoInforme.GADF_F_081) {
              // this.validarAnexo014_081 = true;
              // this.idAnexoGADF_F_081 = element.id;
              // }
              // else {
              if (element.idTipoAnexo == EnumCtoTipoAnexoInforme.GADF_F_014) {
                // this.validarAnexo014_081 = false;
                this.idAnexoGADF_F_014 = element.id;
              }
              // }
            });
          } else {
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.lineaBase.blockedDocument = false;
      }
    );
  }

  /**
   * Lista las asignaciones asociadas al usuario logueado
   */
  cargarInventarioAsignado() {
    forkJoin(
      this.inventarioEquiposService.srvConsultarBienesAniYPersonal(EnumInvInventario.INV_BIEN_TIPO_PROCEDENCIA_ANI)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.bienesAniPersonal = result.bienesAniPersonal;
          } else {
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          this.lineaBase.lanzarMensajeError(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO.toString());
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
        this.lineaBase.lanzarMensajeError(error);
      }
    );
  }

  // /**
  //  * Metodo que maneja el evento de selección para la variable @validarAnexo014_081 que determina que formato se debe subir
  //  * @param sinoSeleccion Objeto de tipo TrnSiNo
  //  */
  // onSelectValidarAnexo14_81(sinoSeleccion: TrnSiNo) {
  //   this.validarAnexo014_081 = sinoSeleccion.valor;
  // }

  irVerDialogoSubirAnexo014() {
    this.visibleDialogoSubirAnexo = true;
    this.armarAnexoInforme(this.idAnexoGADF_F_014, EnumCtoTipoAnexoInforme.GADF_F_014, EnumCtoTipoAnexoInforme.GADF_F_014_NOMBRE, 1);
  }

  // irVerDialogoSubirAnexo081() {
  //   this.visibleDialogoSubirAnexo = true;
  //   this.armarAnexoInforme(this.idAnexoGADF_F_081, EnumCtoTipoAnexoInforme.GADF_F_081, EnumCtoTipoAnexoInforme.GADF_F_081_NOMBRE, 1);
  // }

  /**
   * Metodo que arma el objeto del anexo a guardar
   * @param idAnexo 
   * @param idTipoAnexo 
   * @param nombreTipoAnexo 
   */
  armarAnexoInforme(idAnexo: number, idTipoAnexo: number, nombreTipoAnexo: string, cantidadMaximaAdjuntosAnexo: number) {
    this.anexoInformeContratista = {};
    this.anexoInformeContratista.id = idAnexo;
    this.anexoInformeContratista.idTipoAnexo = idTipoAnexo;
    this.anexoInformeContratista.rutaSoporte = this.rutaCloudStorageAnexos;
    this.anexoInformeContratista.idInforme = this.lineaBase.idInforme;

    this.nombreTipoAnexo = nombreTipoAnexo;
    this.cantidadMaximaAdjuntos = cantidadMaximaAdjuntosAnexo;
  }

  /**
   * Metodo que llama el servicio de cargar adjuntos por tipo de anexo y luego guarda los adjuntos nuevos
   */
  aceptarAdjuntoAnexo() {
    this.lineaBase.blockedDocument = true;
    this.rutaCloudStorageAnexos = this.adjuntosComponente[0].nombreCompleto;

    forkJoin(
      this.informeContratistaService.getAdjuntosPorAnexo(this.anexoInformeContratista.id, this.lineaBase.esBorrador)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.adjuntosAnexosList = result.adjuntos;
            const tamanioFinalAdjuntos: number = this.adjuntosComponente.length + this.adjuntosAnexosList.length;
            if (tamanioFinalAdjuntos <= this.cantidadMaximaAdjuntos) {
              if (this.lineaBase.esBorrador) {
                this.informeContratistaService.srvGuardarAnexoInformeBorrador(this.anexoInformeContratista).subscribe(
                  (data: {}) => {
                    this.anexoInformeContratista = data;
                    let adjuntosBorradorList: CtoAdjuntoInformeBorradorDTO[] = [];
                    this.adjuntosComponente.forEach(element => {
                      let adjuntoBorrador: CtoAdjuntoInformeBorradorDTO = {};
                      adjuntoBorrador.idAnexo = this.anexoInformeContratista.id;
                      adjuntoBorrador.nombreArchivoUsuario = element.nombre;
                      adjuntoBorrador.rutaSoporte = element.nombreCompleto;
                      adjuntosBorradorList.push(adjuntoBorrador);
                    });
                    this.informeContratistaService.srvGuardarAdjuntoInformeBorrador(adjuntosBorradorList).subscribe((data: {}) => {
                      this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                    });

                    this.cargarAnexos(this.lineaBase.idInforme, this.lineaBase.esBorrador);

                    this.visibleDialogoSubirAnexo = false;
                    this.adjuntosComponente = [];
                  });
              } else {
                this.informeContratistaService.srvGuardarAnexoInforme(this.anexoInformeContratista).subscribe(
                  result => {
                    this.anexoInformeContratista = result;
                    let adjuntosBorradorList: CtoAdjuntoInformeBorradorDTO[] = [];
                    this.adjuntosComponente.forEach(element => {
                      let adjuntoBorrador: CtoAdjuntoInformeBorradorDTO = {};
                      adjuntoBorrador.idAnexo = this.anexoInformeContratista.id;
                      adjuntoBorrador.nombreArchivoUsuario = element.nombre;
                      adjuntoBorrador.rutaSoporte = element.nombreCompleto;
                      adjuntosBorradorList.push(adjuntoBorrador);
                    });
                    this.informeContratistaService.srvGuardarAdjuntoInforme(adjuntosBorradorList).subscribe(
                      result => {
                        this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                      },
                      error => {
                        this.principalComponent.cargarErrorServicio(error);
                      }
                    );

                    this.cargarAnexos(this.lineaBase.idInforme, this.lineaBase.esBorrador);

                    this.visibleDialogoSubirAnexo = false;
                    this.adjuntosComponente = [];
                    this.lineaBase.blockedDocument = false;
                  },
                  error => {
                    this.principalComponent.cargarErrorServicio(error);
                  }
                );
              }
            } else {
              this.lineaBase.lanzarMensajeWarning('Solo se permiten ' + this.cantidadMaximaAdjuntos + ' archivos máximo por anexo.');
            }
          } else {
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }

  cancelarDialogoSubirAdjuntos() {
    this.visibleDialogoSubirAnexo = false;
    this.anexoInformeContratista = {};
    this.adjuntosComponente = [];
  }

  armarVerAdjuntos() {
    this.anexoInformeContratista = {};
    // if (this.validarAnexo014_081) {
    //   this.anexoInformeContratista.id = this.idAnexoGADF_F_081;
    // } else {
    this.anexoInformeContratista.id = this.idAnexoGADF_F_014;
    // }
  }

  irVerDialogoVerAdjuntos() {
    this.armarVerAdjuntos();
    this.informeContratistaService.getAdjuntosPorAnexo(this.anexoInformeContratista.id, this.lineaBase.esBorrador)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosAnexosList = result.adjuntos;
              this.visibleDialogoVerAdjuntos = true;
              const tamanioFinalAdjuntos: number = this.adjuntosComponente.length + this.adjuntosAnexosList.length;
              if (tamanioFinalAdjuntos > this.cantidadMaximaAdjuntos) {
                this.lineaBase.lanzarMensajeWarning('Solo se permiten ' + this.cantidadMaximaAdjuntos + ' archivos máximo por anexo.');
              }
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          //Desbloquear pantalla
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  cancelarDialogoVerAdjunto() {
    this.visibleDialogoVerAdjuntos = false;
    this.adjuntosAnexosList = [];
  }

  irVerDialogoEliminarAdjunto(adjunto: CtoAdjuntoInformeBorradorDTO) {
    this.idAdjuntoEliminarAnexo = adjunto.id;
    this.visibleDialogoEliminarAdjunto = true;
  }

  cancelarEliminarAdjunto() {
    this.visibleDialogoEliminarAdjunto = false;
    this.idAdjuntoEliminarAnexo = null;
    this.lineaBase.blockedDocument = false;
  }

  aceptarEliminarAdjunto() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoEliminarAdjunto = false;
    if (this.lineaBase.esBorrador) {
      return this.informeContratistaService.srvEliminarAdjuntoInformeBorrador(this.idAdjuntoEliminarAnexo).subscribe(
        result => {
          this.informeContratistaService.getAdjuntosPorAnexo(this.anexoInformeContratista.id, this.lineaBase.esBorrador)
            .subscribe(
              result => {
                if (result != null && result.respuestaServicio != null) {
                  if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.adjuntosAnexosList = result.adjuntos;
                  } else {
                    this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                  }
                }
              },
              error => {
                this.principalComponent.cargarErrorServicio(error);
              }
            );

          this.visibleDialogoEliminarAdjunto = false;
          this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
    } else {
      return this.informeContratistaService.srvEliminarAdjuntoInforme(this.idAdjuntoEliminarAnexo).subscribe(
        result => {
          this.informeContratistaService.getAdjuntosPorAnexo(this.anexoInformeContratista.id, this.lineaBase.esBorrador)
            .subscribe(
              result => {
                if (result != null && result.respuestaServicio != null) {
                  if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.adjuntosAnexosList = result.adjuntos;
                  } else {
                    this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                  }
                }
              },
              error => {
                this.principalComponent.cargarErrorServicio(error);
              }
            );

          this.visibleDialogoEliminarAdjunto = false;
          this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
    }
  }

  /**
   * Metodo que controla el evento para abrir del dialogo para eliminar un anexo e inicializa las variables 
   */
  verDialogoEliminarAnexo(idAnexo: number) {
    if (idAnexo != null && idAnexo > 0) {
      this.idAnexoEliminar = idAnexo;
      this.visibleDialogoEliminarAnexo = true;
    }
  }

  /**
  * Metodo que controla el evento de cierre del dialogo para eliminar un anexo
  */
  cancelarDialogoEliminarAnexo() {
    this.visibleDialogoEliminarAnexo = false;
  }

  /**
   * Metodo que controla el evento para eliminar un anexo y sus adjuntos asociada al informe en borrador o definitivo dado su id
   * @returns Resultado de la operación
   */
  aceptarEliminarAnexo() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoEliminarAnexo = false;
    if (this.lineaBase.esBorrador) {
      return this.informeContratistaService.srvEliminarAnexoInformeBorrador(this.idAnexoEliminar).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El anexo se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeInfo('Ocurió un error al eliminar el anexo intente nuevamente.');
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAnexos(this.lineaBase.idInforme, this.lineaBase.esBorrador);
          this.visibleDialogoEliminarAnexo = false;
          this.lineaBase.blockedDocument = false;
        }
      );
    } else {
      return this.informeContratistaService.srvEliminarAnexoInforme(this.idAnexoEliminar).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El anexo se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeInfo('Ocurió un error al eliminar el anexo intente nuevamente.');
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAnexos(this.lineaBase.idInforme, this.lineaBase.esBorrador);
          this.visibleDialogoEliminarAnexo = false;
          this.lineaBase.blockedDocument = false;
        }
      );
    }
  }
}


