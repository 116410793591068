import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CtoNovedadContratoDTO } from '../../../../contratacion-dto/cto-novedad-contrato';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { MessageService } from 'primeng/api';
import { NovedadContratoBaseComponent } from '../novedad-contrato-base/novedad-contrato-base.component';
import { CtoTipoNovedadDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-novedad';

@Component({
  selector: 'app-novedad-contrato-suspension',
  templateUrl: './novedad-contrato-suspension.component.html',
  styles: []
})
export class NovedadContratoSuspensionComponent implements OnInit {

  @Input()
  novedadContrato: CtoNovedadContratoDTO = {};
  @Input()
  ctoContratoInformacionVigente: CtoContratoInformacionVigenteEncabezadoDTO;
  @Input()
  tipoNovedad: CtoTipoNovedadDTO;
  @Output()
  ctoNovedadOutput = new EventEmitter<CtoNovedadContratoDTO>();


  visibleDialogoAgregarRegistro = false;
  visibleDialogoExito = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;


  constructor(
    public lineaBase: NovedadContratoBaseComponent
  ) { }


  ngOnInit() {
    this.novedadContrato.novedadContratoFuentes = null;
  }

  /**
  * Metodo que valida y emite el objeto de CtoNovedadContratoDTO al metodo principal de guardarNovedad al dar clic en el botón guardar
  */
  guardarNovedad() {
    if (this.validarNovedad()) {
      this.ctoNovedadOutput.emit(this.novedadContrato);
    }
  }

  /**
   * Metodo que valida la información a guardar para la novedad
   * @returns Verdadero o Falso
   */
  validarNovedad(): boolean {

    let esNovedadValida = true;

    const fechaMax = this.lineaBase.addDays(this.ctoContratoInformacionVigente.fechaTerminacionContrato, this.lineaBase.diasHabilitaFechaMaxCalendario);

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion > fechaMax) {
      esNovedadValida = false;

      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMayorFechaFinContrato);
    }

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion < this.ctoContratoInformacionVigente.fechainicio) {
      esNovedadValida = false;

      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMenorFechaInicialContrato);
    }

    if (this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion > fechaMax) {
      esNovedadValida = false;

      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMayorFechaFinContrato);
    }

    if (this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion < this.ctoContratoInformacionVigente.fechainicio) {
      esNovedadValida = false;

      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMenorFechaInicialContrato);
    }

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion < this.novedadContrato.fechaSuscripcion) {
      esNovedadValida = false;

      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMenorFechaSuscripcion);
    }

    if (this.novedadContrato.plazoAnos == null && this.novedadContrato.plazoMeses == null && this.novedadContrato.plazoDias == null) {
      esNovedadValida = false;

      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValPlazosNulos);
    }

    if (this.novedadContrato.plazoAnos != null && this.novedadContrato.plazoMeses != null && this.novedadContrato.plazoDias != null
      && this.novedadContrato.plazoAnos === 0 && this.novedadContrato.plazoMeses === 0 && this.novedadContrato.plazoDias === 0) {
      esNovedadValida = false;

      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValPlazosEnCero);
    }

    return esNovedadValida;
  }



}
