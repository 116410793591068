import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CtoNovedadContratoDTO } from '../../../../contratacion-dto/cto-novedad-contrato';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { MessageService } from 'primeng/api';
import { NovedadContratoBaseComponent } from '../novedad-contrato-base/novedad-contrato-base.component';
import { CtoTipoNovedadDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-novedad';
import { TrnSiNo } from 'src/app/parametricas/trn-si-no';
import { EnumCtoOrigenRecurso } from 'src/app/contratacion/constantes/contratacion-constantes';

@Component({
  selector: 'app-novedad-contrato-otro-si',
  templateUrl: './novedad-contrato-otro-si.component.html',
  styles: []
})
export class NovedadContratoOtroSiComponent implements OnInit {

  @Input()
  novedadContrato: CtoNovedadContratoDTO = {};
  @Input()
  ctoContratoInformacionVigente: CtoContratoInformacionVigenteEncabezadoDTO;
  @Input()
  tipoNovedad: CtoTipoNovedadDTO;
  @Output()
  ctoNovedadOutput = new EventEmitter<CtoNovedadContratoDTO>();

  novedadContratoAjustar: CtoNovedadContratoDTO = {};

  visibleDialogoAgregarRegistro = false;
  visibleDialogoExito = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;

  constructor(
    public lineaBase: NovedadContratoBaseComponent
  ) { }


  ngOnInit() {
    this.novedadContrato.novedadContratoFuentes = null;
    this.cargarInformacionInicialContrato();
  }

  /**
   * Metodo para cargar la información inicial del contrato daod que esta novedad modifica los valores iniciales
   */
  cargarInformacionInicialContrato() {
    this.novedadContrato.plazoAnos = this.ctoContratoInformacionVigente.plazoAnos;
    this.novedadContrato.plazoMeses = this.ctoContratoInformacionVigente.plazoMeses;
    this.novedadContrato.plazoDias = this.ctoContratoInformacionVigente.plazoDias;
    this.novedadContrato.esResponsableIva = this.ctoContratoInformacionVigente.esResponsableIva;
    this.novedadContrato.estaObligadoFacturar = this.ctoContratoInformacionVigente.estaObligadoFacturar;
    this.novedadContrato.honorariosMensuales = this.ctoContratoInformacionVigente.honorariosMensuales;
    this.novedadContrato.objetoContrato = this.ctoContratoInformacionVigente.objetoContrato;
  }



  /**
  * Metodo de evento de seleción para determinar si un valor es nulo, verdadero o falso, y se asigna a la variable EsResponsableIva.
  * Se agrega validación cuando la variable esResponsableIva es verdadera, para cambiar el estado a null de la variable estaObligadoFacturar 
  * @param sinoSeleccion Objeto de tipo TrnSiNo
  */
  onSelectEsResponsableIva(sinoSeleccion: TrnSiNo) {
    this.novedadContrato.esResponsableIva = sinoSeleccion.valor;
    if (this.novedadContrato.esResponsableIva) {
      this.novedadContrato.estaObligadoFacturar = null;
    }
  }

  /**
   * Metodo de evento de seleción para determinar si un valor es nulo, verdadero o falso, y se asigna a la variable EstaObligadoFacturar.
   * @param sinoSeleccion Objeto de tipo TrnSiNo
   */
  onSelectEstaObligadoFacturar(sinoSeleccion: TrnSiNo) {
    this.novedadContrato.estaObligadoFacturar = sinoSeleccion.valor;
  }

  /**
   * Metodo que valida y emite el objeto de CtoNovedadContratoDTO al metodo principal de guardarNovedad al dar clic en el botón guardar
   */
  guardarNovedad() {
    if (this.validarNovedad()) {
      this.ctoNovedadOutput.emit(this.novedadContrato);
      // this.lineaBase.irHistoricoNovedadesPorContrato();//Quitar esta línea cuando se definia el modulo
    }
  }

  /**
   * Metodo que valida la información a guardar para la novedad
   * @returns Verdadero o Falso
   */
  validarNovedad(): boolean {

    let esNovedadValida = true;

    const fechaMax = this.lineaBase.addDays(this.ctoContratoInformacionVigente.fechaTerminacionContrato, this.lineaBase.diasHabilitaFechaMaxCalendario);

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion > fechaMax) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMayorFechaFinContrato);
    }

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion < this.ctoContratoInformacionVigente.fechainicio) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMenorFechaInicialContrato);
    }

    if (this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion > fechaMax) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMayorFechaFinContrato);
    }

    if (this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion < this.ctoContratoInformacionVigente.fechainicio) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMenorFechaInicialContrato);
    }

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion < this.novedadContrato.fechaSuscripcion) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMenorFechaSuscripcion);
    }

    if (this.novedadContrato.novedadContratoFuentes == null || (this.novedadContrato.novedadContratoFuentes != null && this.novedadContrato.novedadContratoFuentes.length == 0)) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFuentesFinanciacionVacia);
    }

    if (this.novedadContrato.esResponsableIva == null) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValCesionEsResponsableIvaNulo);
    }

    if (this.novedadContrato.esResponsableIva != null && !this.novedadContrato.esResponsableIva && this.novedadContrato.estaObligadoFacturar == null) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValCesionEstaObligadoFacturarNulo);
    }

    if (this.novedadContrato.idImputacionRecurso == null) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMenorFechaSuscripcion);
    } else {
      if (this.novedadContrato.idImputacionRecurso != null) {
        if (this.novedadContrato.idImputacionRecurso != null && this.novedadContrato.novedadContratoFuentes != null && this.novedadContrato.novedadContratoFuentes.length != 0) {
          // Si es un Otro sí cargar información de imputación de recursos y por ende filtrar las fuentes
          // 1	Funcionamiento -> OrigenRecurso - 1 - ANI
          // 2	Inversión	->	OrigenRecurso - 1 - ANI
          // 3	Fiducia	->	OrigenRecurso - 2 - Patrimonio autónomo
          // 4	Funcionamiento, fiducia	->	OrigenRecurso - 3 - Mixto
          // 5	Inversión, fiducia	->	OrigenRecurso - 3 - Mixto

          const fuenteAni = this.novedadContrato.novedadContratoFuentes.filter(fnt => fnt.idFuente == EnumCtoOrigenRecurso.ANI).length;
          const fuentesFiducias = this.novedadContrato.novedadContratoFuentes.filter(fnt => fnt.idFuente != EnumCtoOrigenRecurso.ANI).length;

          if ((this.novedadContrato.idImputacionRecurso == 1 || this.novedadContrato.idImputacionRecurso == 2) && (fuenteAni == 0)) {
            // Validar que solo hayan fuentes ANI
            esNovedadValida = false;
            this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFuentesFinanciacionANIVacia);
          } else if ((this.novedadContrato.idImputacionRecurso == 3) && (fuentesFiducias == 0)) {
            // Validar que solo hayan fuentes FIDUCIA
            esNovedadValida = false;

            this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFuentesFinanciacionFIDUCIASVacia);
          } else if ((this.novedadContrato.idImputacionRecurso == 4 || this.novedadContrato.idImputacionRecurso == 5) && (fuenteAni == 0 || fuentesFiducias == 0)) {
            // Validar que solo hayan fuentes FIDUCIA - ANI
            esNovedadValida = false;
            this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFuentesFinanciacionMIXTASVacia);
          }
        }
      }
    }
    return esNovedadValida;
  }

}
