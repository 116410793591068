import { Component, OnInit } from '@angular/core';
import { InformeContratistaService } from '../informe-contratista.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EnumCtoPasoInforme, EnumCtoTipoAnexoInforme } from '../../../../constantes/contratacion-constantes';
import { EnumRespuestaServicio, EnumTipoDocumento, EnumTrnConstante, EnumTrnMaTipoSoporte } from '../../../../../comun/constantes/constantes-comunes';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { TrnPersona } from 'src/app/admin-personas/trn-persona';
import { CtoPlanPagoDetalleDTO } from '../../../../contratacion-dto/contrato-plan-pago-vigente';
import { CtoContratoInformacionVigenteEncabezadoDTO } from '../../../../contratacion-dto/contrato-informacion-vigente-encabezado';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumInvInventario } from 'src/app/inventario-equipos/inventario-constantes';
import { UspCtoValidaInformeContratistaDTO } from '../../../../contratacion-dto/informe-contratista-validacion-informe';
import { CtoInformeContratistaDTO } from 'src/app/contratacion/contratacion-dto/informe-contratista';
import { TrnConstantesService } from 'src/app/comun/constantes/trn-constantes.service';
import { forkJoin } from 'rxjs';
import { CtoMaTipoDeduccionDeclaracionRentaDTO } from 'src/app/contratacion/contratacion-dto/cto-ma-tipo-deduccion-declaracion-renta';

@Component({
  selector: 'app-informe-contratista-base',
  templateUrl: './informe-contratista-base.component.html',
  styles: []
})
export class InformeContratistaBaseComponent implements OnInit {

  public numeroContrato: any;
  public vigencia: any;
  public idContrato: any;
  public idPersona: number;
  public personaContratista: TrnPersona;
  public idPlanPago: any;
  public idInforme: any;
  public esBorrador = true;
  public visibleBotonEnviarInforme = false;
  public version: any;
  public paso: any;
  public esResponsableIva: boolean;
  public estaObligadoFacturar: boolean;
  public numeroPago: number;
  public numeroDocumento: string;
  public periodoPagoContratoContratistaInforme: CtoPlanPagoDetalleDTO;
  infoContrato: CtoContratoInformacionVigenteEncabezadoDTO = {};
  informeContratista: CtoInformeContratistaDTO;
  tiposDeduccionDeclaracionRenta: CtoMaTipoDeduccionDeclaracionRentaDTO[] = [];

  public visibleDialogoConfirmarPublicarInforme = false;
  validaInformeContratista: UspCtoValidaInformeContratistaDTO[] = [];
  validaInformeContratistaMensaje = "";

  habilitaTabValidacionInforme = false;
  visibleDialogoValidacionPublicarInforme = false;
  blockedDocument = false;
  visibleDialogoConfirmarRevisionInforme = false;
  habilitaEdicion: boolean;//Este campo tiene el nombre al revés, si esta falso, quiere decir que permite editar sí es true no permite editar

  habilitaTabPazSalvo = false;
  habilitaAnexosPazSalvo = false;
  dateMesDiciembre = 11;//Debe ser 0-11, 11 Es Diciembre

  mapConstantes;

  constructor(
    private route: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private contratacionService: ContratacionService,
    private informeContratistaService: InformeContratistaService,
    private personaService: PersonaService,
    private constantesService: TrnConstantesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.informeContratista = null;

    this.mapConstantes = new Map();
    this.cargarConstantesCuentasCobro();

    this.cargarParametricasGenerales();

    this.ruta.params.subscribe(params => {
      this.idContrato = params.idContrato;
      this.idPersona = params.idPersona;
      this.idPlanPago = params.idPlanPago;
      this.idInforme = params.idInforme;
      this.version = params.version;
      this.paso = params.paso;
      this.numeroPago = params.numeroPago;

      const paramEsResponsableIva: number = params.esResponsableIva;
      if (paramEsResponsableIva == 1) {
        this.esResponsableIva = true;
      } else {
        this.esResponsableIva = false;
      }

      const paramEstaObligadofacturar: number = params.estaObligadoFacturar;
      if (paramEstaObligadofacturar == 1) {
        this.estaObligadoFacturar = true;
      } else {
        this.estaObligadoFacturar = false;
      }

      const paramBorrador: number = params.borrador;
      if (paramBorrador == 1) {
        this.esBorrador = true;
      } else {
        this.esBorrador = false;
      }
      if (this.esBorrador) {
        this.habilitaEdicion = false;
      } else {
        if (this.paso == EnumCtoPasoInforme.EN_CORRECCIONES || this.paso == EnumCtoPasoInforme.RECHAZADO) {
          this.habilitaEdicion = false;
        } else {
          this.habilitaEdicion = true;
        }
      }


      if (this.paso == EnumCtoPasoInforme.EN_CORRECCIONES || this.paso == EnumCtoPasoInforme.RECHAZADO) {
        this.visibleBotonEnviarInforme = true;
      }

      this.cargarInformacionContratoContratistaPeriodoPagoInforme(this.idContrato, this.idPersona, this.idPlanPago);
    });
  }

  /**
   * Metodo que llama al servicio de constantes para mostrar en el dialogo de información de las cuentas de cobro
   */
  cargarConstantesCuentasCobro() {
    const nombresConstantes = `${EnumTrnConstante.GENERAL_CORREO_SOPORTE},${EnumTrnConstante.CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE},${EnumTrnConstante.CTO_CONTRATACION_CORREO_SOPORTE},${EnumTrnConstante.CTO_PLAN_PAGOS_TESORERIA_SOPORTE},${EnumTrnConstante.CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE},${EnumTrnConstante.CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA}`;
    this.constantesService.srvGetConstantesListaPorNombre(nombresConstantes)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.mapConstantes = new Map();
              result.constantesLista.forEach(cns => {
                this.mapConstantes.set(cns.nombre, cns.valor);
              });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
  }

  /**
  * Metodo que llama al servicio de consulta de las parametricas generales para el informe contratista 
  */
  cargarParametricasGenerales() {
    forkJoin(
      //Cargar Tipo Deduccion Declaracion Renta
      this.informeContratistaService.srvGetCtoMaTipoDeduccionDeclaracionRenta()
    ).subscribe(([resultTipoDeduccion]) => {
      if (resultTipoDeduccion != null && resultTipoDeduccion.respuestaServicio != null) {
        if (resultTipoDeduccion.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.tiposDeduccionDeclaracionRenta = resultTipoDeduccion.tiposDeduccionDeclaracionRenta;
        }
      } else {
        console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  /**
   * Metodo que consulta la información del periodo de pago y contratista, ya que puede ser difernete el contratista vigente del contrato versus el que pasa la cuenta de cobro
   * @param idContrato Id de Contrato
   * @param idPersona Id de la Persona contratista del informe
   * @param idPlanPago Id del Plan Pago del informe
   */
  cargarInformacionContratoContratistaPeriodoPagoInforme(idContrato: number, idPersona: number, idPlanPago: number) {
    this.blockedDocument = true;
    //Cargar informacion Contrato
    this.contratacionService.srvConsultarContratosInformacionVigente(idContrato, null, null, null, null, null, null, 0, 1, null, 'Id', 1)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.infoContrato = result.contratoInformacionVigenteEncabezado;

              //Mapear fechas contrato
              this.infoContrato.fechaTerminacionContrato = new Date(this.infoContrato.fechaTerminacionContrato);

              this.numeroContrato = this.infoContrato.numeroContrato;
              this.vigencia = this.infoContrato.vigencia;

              if (this.infoContrato) {
                //Cargar PeriodoPagoContratoContratistaInforme
                this.informeContratistaService.srvGetPeriodoPagoContratoContratistaInforme(this.infoContrato.id, idPersona, idPlanPago).subscribe(
                  result => {
                    if (result != null && result.respuestaServicio != null) {
                      if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                        this.periodoPagoContratoContratistaInforme = result.contratoPlanPagoVigenteRegistro;

                        if (this.periodoPagoContratoContratistaInforme.numeroPago == this.periodoPagoContratoContratistaInforme.totalPagosFuente
                          //Esta sección se agrego a partir del periodo de Diciembre 2021
                          && this.vigencia >= this.mapConstantes.get(EnumTrnConstante.CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA)) {
                          this.habilitaTabPazSalvo = true;
                          //Esta regla se agregó a partir del periodo de Diciembre 2023.
                          //Se obliga anexar los formatos 014 para la útlima cuenta de cobro del contrato siempre y cuando la FechaFin del contrato sea menor o igual a 18/Dic

                          const fechaValidacion = new Date(this.infoContrato.fechaTerminacionContrato.getFullYear(), this.dateMesDiciembre, this.mapConstantes.get(EnumTrnConstante.CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE));

                          if (this.infoContrato.fechaTerminacionContrato <= fechaValidacion) {
                            this.habilitaAnexosPazSalvo = true;
                          }
                        }

                        //Cargar Informacion Constratista
                        this.personaService.ConsultarDatosBasicosPersonaId(EnumTipoDocumento.CC, this.periodoPagoContratoContratistaInforme.idPersona).subscribe(
                          result => {
                            this.personaContratista = result.persona;
                            this.numeroDocumento = this.personaContratista.numeroDocumento;
                            this.blockedDocument = false;
                          },
                          error => {
                            this.principalComponent.cargarErrorServicio(error);
                            this.lanzarMensajeError(error);
                            this.blockedDocument = false;
                          }
                        );


                        //Cargar Informe Contratista en borrador o definitivo
                        this.informeContratistaService.srvGetInformeContratista(this.idInforme, this.esBorrador)
                          .subscribe(result => {
                            if (result != null && result.respuestaServicio != null) {
                              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                                this.informeContratista = result.informeContratista;
                                //Se actualiza la variable el objeto this.informeContratista dado que puede cambiar en cualquier momento del tiempo las variables esResponsableIva, estaObligadoFacturar que viene desde el plan de pagos por la lineaBase
                                this.informeContratista.esResponsableIva = this.esResponsableIva;
                                this.informeContratista.estaObligadoFacturar = this.estaObligadoFacturar;
                                this.informeContratista.idPlanPago = this.periodoPagoContratoContratistaInforme.idPlanPago;


                              }
                            }
                          });

                      } else {
                        this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        this.blockedDocument = false;
                      }
                    } else {
                      this.blockedDocument = false;
                    }
                  },
                  error => {
                    this.principalComponent.cargarErrorServicio(error);
                    this.blockedDocument = false;
                  }
                );
              }

            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              this.blockedDocument = false;
            }
          } else {
            this.blockedDocument = false;
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        }
      );
  }

  /**
   * Metodo con el cual se define si es obligatorio presentar factura
   * @returns Verdadero o Falso
   */
  validarCargarFacturaObligatoriedad() {
    let debeCargarFactura = false;
    if (this.esResponsableIva) {
      debeCargarFactura = true;
    } else {
      if (!this.esResponsableIva && this.estaObligadoFacturar) {
        debeCargarFactura = true;
      }
    }
    return debeCargarFactura;
  }

  /**
   * Metodo que se encarga de llamar al servicio para publicar el informe de la cuenta de cobro cuando es un borrador
   */
  publicarInforme() {
    this.blockedDocument = true;
    this.visibleDialogoConfirmarPublicarInforme = false;
    this.informeContratistaService.publicarInforme(this.idInforme).subscribe(
      result => {
        if (result != null) {
          if (result.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.irPlanPagoContratoContratista();
          } else {
            this.validaInformeContratista = result.validaInformeContratista;
            this.validaInformeContratistaMensaje = result.mensajeSalida;
            if (this.validaInformeContratista.length != 0) {
              this.habilitaTabValidacionInforme = true;
            }
            this.visibleDialogoValidacionPublicarInforme = true;
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
  * Metodo que se encarga de llamar al servicio para publicar el informe de la cuenta de cobro cuando no es un borrador
  */
  enviarInforme() {
    this.blockedDocument = true;
    this.visibleDialogoConfirmarRevisionInforme = false;
    this.informeContratistaService.srvEnviarInforme(this.idInforme, this.version).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.irPlanPagoContratoContratista();
          } else {
            this.validaInformeContratista = result.validaInformeContratista;
            this.validaInformeContratistaMensaje = result.respuestaServicio.mensajeSalida
            if (this.validaInformeContratista != null && this.validaInformeContratista.length != 0) {
              this.habilitaTabValidacionInforme = true;
              this.visibleDialogoValidacionPublicarInforme = true;
            }
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  outputInicioVisualizarArchivo() {
    this.blockedDocument = true;
  }

  outputFinVisualizarArchivo() {
    this.blockedDocument = false;
  }


  irPlanPagoContratoContratista() {
    this.route.navigate(['misContratos/planPagosContratista', this.idContrato, this.idPersona]);
  }

  get EnumTipoDocumento() {
    return EnumTipoDocumento;
  }
  get EnumTrnConstante() {
    return EnumTrnConstante;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumInvInventario() {
    return EnumInvInventario;
  }
  get EnumCtoTipoAnexoInforme() {
    return EnumCtoTipoAnexoInforme;
  }


  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.messageService.add({ severity: "error", summary: "Error", detail: mensaje, life: 10000, });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}

