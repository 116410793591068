import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCtoTipoNovedadContrato, EnumCtoOrigenRecurso } from 'src/app/contratacion/constantes/contratacion-constantes';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { CtoFuenteFinanciacionDTO } from 'src/app/contratacion/contratacion-dto/cto-fuente-financiacion';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoNovedadContratoDTO } from '../../../../contratacion-dto/cto-novedad-contrato';
import { NovedadesConstractualesService } from '../../novedades-contractuales.service';
import { CtoTipoNovedadDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-novedad';
import { CtoNovedadLiberacionFuenteDTO } from '../../../../contratacion-dto/cto-novedad-contrato-fuente';
import { NovedadContratoBaseComponent } from '../novedad-contrato-base/novedad-contrato-base.component';

@Component({
  selector: 'app-novedad-contrato-fuente-financiacion-liberacion',
  templateUrl: './novedad-contrato-fuente-financiacion-liberacion.component.html',
  styles: []
})
export class NovedadContratoFuenteFinanciacionLiberacionComponent implements OnInit {

  @Input()
  novedadContrato: CtoNovedadContratoDTO;
  @Input()
  ctoContratoInformacionVigente: CtoContratoInformacionVigenteEncabezadoDTO;
  @Input()
  tipoNovedad: CtoTipoNovedadDTO;
  @Output()
  fuentesNovedadPorContratoOutput = new EventEmitter<CtoNovedadContratoDTO>();

  fuenteNovedadLiberacionSeleccionada: CtoNovedadLiberacionFuenteDTO;

  fuentesFinanciacion: CtoFuenteFinanciacionDTO[];
  fuenteFinanciacionSeleccionada: CtoFuenteFinanciacionDTO;
  mostrarInformacionPresupuestal = false;


  visibleDialogoRegistro = false;
  visibleDialogoExito = false;

  constructor(
    public lineaBase: NovedadContratoBaseComponent,
    private contratacionService: ContratacionService,
    private novedadesConstractualesService: NovedadesConstractualesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarFuentesFinanciacion();
    if (this.tipoNovedad != null && this.tipoNovedad.id == EnumCtoTipoNovedadContrato.CTO_TIPO_NOVEDAD_LIBERACION_RECURSOS) {
      this.consultarContratoFuentesFinanciacionNovedadLiberacion();
    }
    this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
  }

  consultarContratoFuentesFinanciacionNovedadLiberacion() {
    this.novedadesConstractualesService.ConsultarContratoFuentesFinanciacionNovedadLiberacion(this.ctoContratoInformacionVigente.id).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.novedadContrato.novedadLiberacionFuente = result.novedadLiberacionContratoFuentes;
          } else {
            this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
            console.error(result.respuestaServicio.mensajeSalida);
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
      }
    );
  }

  cargarFuentesFinanciacion() {
    this.contratacionService.GetFuentesFinanciacion()
      .subscribe(
        result => {
          this.fuentesFinanciacion = result;
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
  }

  validarMostrarInformacionPresupuestal() {
    this.fuenteNovedadLiberacionSeleccionada = {};
    if (this.fuenteFinanciacionSeleccionada != null) {
      if (this.fuenteFinanciacionSeleccionada.id === 1) {
        this.mostrarInformacionPresupuestal = true;
      } else {
        this.mostrarInformacionPresupuestal = false;
      }
    }
  }

  validarAgregarFuenteNovedad(): boolean {
    let validacionFuente = true;
    if (this.fuenteNovedadLiberacionSeleccionada == null) {
      validacionFuente = false;
      this.lineaBase.lanzarMensajeError('No ha ingresado la información presupuestal');
    } else {
      if (this.fuenteNovedadLiberacionSeleccionada.valor == null || this.fuenteNovedadLiberacionSeleccionada.valor <= 0) {
        validacionFuente = false;
        this.lineaBase.lanzarMensajeError('La fuente no puede tener un Valor 0 para liberar');
      }

      if (this.fuenteNovedadLiberacionSeleccionada.valor == null || this.fuenteNovedadLiberacionSeleccionada.valor > this.fuenteNovedadLiberacionSeleccionada.valorFuente) {
        validacionFuente = false;
        this.lineaBase.lanzarMensajeError('No puede liberar un valor mayor al total de la fuente actual');
      }
    }
    return validacionFuente;
  }

  irLiberarRecursoFuente(fuenteNovedad: CtoNovedadLiberacionFuenteDTO) {
    this.fuenteNovedadLiberacionSeleccionada = {};
    this.fuenteNovedadLiberacionSeleccionada = fuenteNovedad;
    this.fuenteNovedadLiberacionSeleccionada.fechaSuscripionNovedad = new Date(this.fuenteNovedadLiberacionSeleccionada.fechaSuscripionNovedad);

    if (this.fuenteNovedadLiberacionSeleccionada.idTipoFuente == EnumCtoOrigenRecurso.ANI) {
      this.fuenteNovedadLiberacionSeleccionada.fechaCdp = new Date(this.fuenteNovedadLiberacionSeleccionada.fechaCdp);
      this.fuenteNovedadLiberacionSeleccionada.fechaRp = new Date(this.fuenteNovedadLiberacionSeleccionada.fechaRp);
    } else {
      this.fuenteNovedadLiberacionSeleccionada.fechaCdp = null;
      this.fuenteNovedadLiberacionSeleccionada.fechaRp = null;
    }
    this.fuenteFinanciacionSeleccionada = this.fuentesFinanciacion.find(fnt => fnt.id == this.fuenteNovedadLiberacionSeleccionada.idTipoFuente);
    this.visibleDialogoRegistro = true;
  }

  aceptarLiberarFuenteNovedadContrato() {
    if (this.validarAgregarFuenteNovedad()) {

      if (this.novedadContrato.novedadLiberacionFuente == null) {
        this.novedadContrato.novedadLiberacionFuente = [];
      }

      if (this.fuenteNovedadLiberacionSeleccionada != null) {
        //Validar si es un fuente contrato inicial o de una novedad
        if (this.fuenteNovedadLiberacionSeleccionada.idNovedad == null) {
          this.fuenteNovedadLiberacionSeleccionada.idFuenteInicial = this.fuenteNovedadLiberacionSeleccionada.idFuente;
        } else {
          this.fuenteNovedadLiberacionSeleccionada.idFuenteAdicion = this.fuenteNovedadLiberacionSeleccionada.idFuente;
        }
        // this.novedadContrato.novedadLiberacionContratoFuentes.push(this.fuenteNovedadLiberacionSeleccionada);
        // Emitter para notificar al compoenente de la novedad;
        this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
        this.fuenteNovedadLiberacionSeleccionada = null;
        this.visibleDialogoRegistro = false;
      }
    }
  }

  cancelarLiberarRecursoFuente() {
    this.fuenteNovedadLiberacionSeleccionada = null;
    this.fuenteFinanciacionSeleccionada = null;
    this.visibleDialogoRegistro = false;
  }

  get EnumCtoTipoNovedadContrato() {
    return EnumCtoTipoNovedadContrato;
  }

  get EnumCtoOrigenRecurso() {
    return EnumCtoOrigenRecurso;
  }
}
