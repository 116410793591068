import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CtoNovedadContratoDTO } from '../../../../contratacion-dto/cto-novedad-contrato';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { MessageService } from 'primeng/api';
import { NovedadContratoBaseComponent } from '../novedad-contrato-base/novedad-contrato-base.component';
import { CtoTipoNovedadDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-novedad';

@Component({
  selector: 'app-novedad-contrato-prorroga',
  templateUrl: './novedad-contrato-prorroga.component.html',
  styles: []
})
export class NovedadContratoProrrogaComponent implements OnInit {

  @Input()
  novedadContrato: CtoNovedadContratoDTO = {};
  @Input()
  ctoContratoInformacionVigente: CtoContratoInformacionVigenteEncabezadoDTO;
  @Input()
  tipoNovedad: CtoTipoNovedadDTO;
  @Output()
  ctoNovedadOutput = new EventEmitter<CtoNovedadContratoDTO>();

  esTiempoEjecucionContratoEnFecha = false;

  visibleDialogoAgregarRegistro = false;
  visibleDialogoExito = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;


  constructor(
    public lineaBase: NovedadContratoBaseComponent
  ) { }


  ngOnInit() {
    this.novedadContrato.novedadContratoFuentes = null;
  }

  /**
  * Metodo que valida y emite el objeto de CtoNovedadContratoDTO al metodo principal de guardarNovedad al dar clic en el botón guardar
  */
  guardarNovedad() {
    if (this.validarNovedad()) {
      this.novedadContrato.fechaAplicacion = this.novedadContrato.fechaSuscripcion;
      this.ctoNovedadOutput.emit(this.novedadContrato);
    }
  }

  validarEsTiempoEjecucionContratoEnFecha(sinoSeleccion) {
    this.esTiempoEjecucionContratoEnFecha = sinoSeleccion.valor;
    if (this.esTiempoEjecucionContratoEnFecha) {
      this.novedadContrato.plazoDias = null;
      this.novedadContrato.plazoMeses = null;
      this.novedadContrato.plazoAnos = null;
    } else {
      this.novedadContrato.nuevaFechaTerminacion = null;
    }
  }

  /**
   * Metodo que valida la información a guardar para la novedad
   * @returns Verdadero o Falso
   */
  validarNovedad(): boolean {

    let esNovedadValida = true;

    const fechaMax = this.lineaBase.addDays(this.ctoContratoInformacionVigente.fechaTerminacionContrato, this.lineaBase.diasHabilitaFechaMaxCalendario);

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion > fechaMax) {
      esNovedadValida = false;

      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMayorFechaFinContrato);
    }

    if (!this.esTiempoEjecucionContratoEnFecha) {
      if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion < this.ctoContratoInformacionVigente.fechainicio) {
        esNovedadValida = false;

        this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMenorFechaInicialContrato);
      }

      if (this.novedadContrato.plazoAnos == null && this.novedadContrato.plazoMeses == null && this.novedadContrato.plazoDias == null) {
        esNovedadValida = false;

        this.lineaBase.lanzarMensajeError(this.lineaBase.msgValPlazosNulos);
      }

      if (this.novedadContrato.plazoAnos != null && this.novedadContrato.plazoMeses != null && this.novedadContrato.plazoDias != null
        && this.novedadContrato.plazoAnos === 0 && this.novedadContrato.plazoMeses === 0 && this.novedadContrato.plazoDias === 0) {
        esNovedadValida = false;

        this.lineaBase.lanzarMensajeError(this.lineaBase.msgValPlazosEnCero);
      }
    } else {
      if (this.novedadContrato.nuevaFechaTerminacion != null && this.novedadContrato.nuevaFechaTerminacion <= this.ctoContratoInformacionVigente.fechaTerminacionContrato) {
        esNovedadValida = false;

        this.lineaBase.lanzarMensajeError(this.lineaBase.msgValNuevaFechaFinMenorFechaFinActualContrato);
      }

    }

    // vALIDACIOND E TIEMPO MAXIMO EL 50% DEL TIEMPO INICIAL DEL CONTRATO
    // if (this.novedadContrato.plazoMeses != null && this.novedadContrato.novedadContratoFuentes.length === 0) {
    //   esNovedadValida = false;
    //   
    //   this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFuentesFinanciacionVacia);
    // }

    return esNovedadValida;
  }



}
