import { Component, OnInit } from '@angular/core';
import { InformeContratistaBaseComponent } from '../informe-contratista-base/informe-contratista-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { InformeContratistaService } from '../informe-contratista.service';
import { CtoAnexoInformeContratistaDTO } from '../../../../contratacion-dto/anexo-informe-contratista';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from '../../../../../comun/constantes/constantes-comunes';
import { EnumCtoTipoAnexoInforme } from 'src/app/contratacion/constantes/contratacion-constantes';
import { TrnSiNo } from 'src/app/parametricas/trn-si-no';
import { CtoMaTipoDeduccionDeclaracionRentaDTO } from 'src/app/contratacion/contratacion-dto/cto-ma-tipo-deduccion-declaracion-renta';


@Component({
  selector: 'app-informe-contratista-beneficios-tributarios',
  templateUrl: './informe-contratista-beneficios-tributarios.component.html',
  styles: []
})
export class InformeContratistaBeneficiosTributariosComponent implements OnInit {

  contratoTrabajadores: boolean = null;
  tiposDeduccionDeclaracionRentaSelecionados: CtoMaTipoDeduccionDeclaracionRentaDTO[] = [];

  adjuntarCuentaAFC: boolean;
  adjuntarCertificacionBancaria: boolean;
  adjuntarCertificacionDependientes: boolean;
  adjuntarCertificacionPensionVoluntaria: boolean;
  adjuntarCertificacionMediciaPrepagada: boolean;



  //Formulario por Planilla
  fechaPagoPlanilla: Date;
  numeroPlanillaVar: string;

  visibleDialogoSubirAFC: boolean;
  visibleDialogoSubirBancaria: boolean;
  visibleDialogoSubirDependientes: boolean;
  visibleDialogoSubirPensionVoluntaria: boolean;
  visibleDialogoSubirMedicinaPrepagada: boolean;

  rutaCuentaAFC: string;
  idCuentaAFC: number;
  idCertificacionBancaria: number;
  idCertificacionDependientes: number;
  idCertificacionPensionVoluntaria: number;
  idCertificacionMediciaPrepagada: number;
  rutaCertificacionBancaria: string;
  rutaCertificacionDependientes: string;
  rutaCertificacionPensionVoluntaria: string;
  rutaCertificacionMediciaPrepagada: string;
  archivos: any = [];//Arreglo de archivos adjuntados en el componente adjuntar-almacenamiento
  visibleDialogoFormularioPlanilla: boolean;
  adjuntosPlanillaList: any[] = [];
  idAdjuntoEliminar: number;
  visibleDialogoEliminarAdjuntoPlanilla: boolean;
  visibleDialogoVerAdjuntosAnexos: boolean;
  idAnexo: number;
  adjuntosAnexosList: any[] = [];
  idAdjuntoEliminarAnexo: number;
  visibleDialogoEliminarAdjunto: boolean;

  idAnexoEliminar: number;
  visibleDialogoEliminarAnexo: boolean;

  rutaCloudStorageAnexos = '';
  cantidadMaximaAdjuntos = 3;

  constructor(
    public lineaBase: InformeContratistaBaseComponent,
    private informeContratistaService: InformeContratistaService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.rutaCloudStorageAnexos = this.lineaBase.vigencia + '/' + this.lineaBase.idContrato + '_' + this.lineaBase.numeroContrato + '/' + this.lineaBase.numeroDocumento + '/' + this.lineaBase.numeroPago + '/anexos/';
    this.cargarInformePlanilla();
    this.cargarAnexosTributariosPorInforme(this.lineaBase.idInforme);
  }



  /**
   * Metodo que inicaliza las variable usadas para el manejo de los anexos y adjuntos de los beneficios tributarios
   */
  limpiarVariables() {
    this.idCuentaAFC = undefined;
    this.rutaCuentaAFC = undefined;
    this.adjuntarCuentaAFC = false;
    this.idCertificacionBancaria = undefined;
    this.rutaCertificacionBancaria = undefined;
    this.adjuntarCertificacionBancaria = false;
    this.idCertificacionDependientes = undefined;
    this.rutaCertificacionDependientes = undefined;
    this.adjuntarCertificacionDependientes = false;
    this.idCertificacionPensionVoluntaria = undefined;
    this.rutaCertificacionPensionVoluntaria = undefined;
    this.adjuntarCertificacionPensionVoluntaria = false;
    this.idCertificacionMediciaPrepagada = undefined;
    this.rutaCertificacionMediciaPrepagada = undefined;
    this.adjuntarCertificacionMediciaPrepagada = false;
    this.idAnexoEliminar = undefined;
  }

  /**
   * Metodo que llama al servicio de consulta de Anexos de informe de acuerdo a los paramteros (EnumCtoTipoAnexoInforme.SUBTIPO_BENEFICIO_TRIBUTARIO)
   * @param idInforme Id del informe
   */
  cargarAnexosTributariosPorInforme(idInforme: number) {
    this.limpiarVariables();
    forkJoin(
      this.informeContratistaService.srvGetAnexosPorSubTipoAnexoInforme(
        EnumCtoTipoAnexoInforme.SUBTIPO_BENEFICIO_TRIBUTARIO, idInforme, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          result.anexos.forEach(element => {
            if (element.idTipoAnexo == EnumCtoTipoAnexoInforme.CERTIFICACION_AFC) {
              this.adjuntarCuentaAFC = true;
              this.rutaCuentaAFC = element.rutaSoporte;
              this.idCuentaAFC = element.id;
            }
            if (element.idTipoAnexo == EnumCtoTipoAnexoInforme.CERTIFICACION_CREDITO_HITPOTECARIO) {
              this.adjuntarCertificacionBancaria = true;
              this.rutaCertificacionBancaria = element.rutaSoporte;
              this.idCertificacionBancaria = element.id;
            }
            if (element.idTipoAnexo == EnumCtoTipoAnexoInforme.CERTIFICADO_DEPENDIENTES) {
              this.adjuntarCertificacionDependientes = true;
              this.rutaCertificacionDependientes = element.rutaSoporte;
              this.idCertificacionDependientes = element.id;
            }
            if (element.idTipoAnexo == EnumCtoTipoAnexoInforme.CERTIFICADO_PENSIONES_VOLUNTARIAS) {
              this.adjuntarCertificacionPensionVoluntaria = true;
              this.rutaCertificacionPensionVoluntaria = element.rutaSoporte;
              this.idCertificacionPensionVoluntaria = element.id;
            }
            if (element.idTipoAnexo == EnumCtoTipoAnexoInforme.CERTIFICADO_MEDICINA_PREPAGADA) {
              this.adjuntarCertificacionMediciaPrepagada = true;
              this.rutaCertificacionMediciaPrepagada = element.rutaSoporte;
              this.idCertificacionMediciaPrepagada = element.id;
            }
          });
        } else {
          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
        }
      } else {
        console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo de evento de seleción para determinar si un valor es nulo, verdadero o falso, y se asigna a la variable contratoTrabajadores.
   * @param sinoSeleccion Objeto de tipo TrnSiNo
   */
  onSelectValidarTieneContratosTrabajadores(sinoSeleccion: TrnSiNo) {
    this.contratoTrabajadores = sinoSeleccion.valor;
  }

  /**
  * Metodo que controla el evento de selección de tipo de deducción
  * @param sinoSeleccion Valor seleccionado (true, false, null)
  */
  onSelectTipoDeduccionDeclaracionRenta(sinoSeleccion: TrnSiNo, rowData: CtoMaTipoDeduccionDeclaracionRentaDTO) {
    if (rowData.seleccionado != sinoSeleccion.valor) {
      const index = this.tiposDeduccionDeclaracionRentaSelecionados;
      index.forEach(item => item.seleccionado = false);
      index.find(item => item.id == rowData.id).seleccionado = sinoSeleccion.valor;
      this.tiposDeduccionDeclaracionRentaSelecionados = index;
    }
  }

  /**
   * Metodo con el cual se valida que los campos del formulario para la planilla esten registrados 
   * @returns Verdadero o Falso
   */
  validarBeneficiosTributarios() {
    let esValido = true;

    //A partir de 2024 este campo no se va a utilizar, dado que se reemplaza por el campo de tipo de deducción
    // if (this.contratoTrabajadores == null) {
    //   this.lineaBase.lanzarMensajeError('El campo ¿He contratado o vinculado dos (2) o más trabajadores o contratistas asociados a la actividad ejecutada en este contrato, por un término inferior a noventa (90) días continuos o discontinuos? es obligatorio');
    //   esValido = false;
    // }
    if (this.tiposDeduccionDeclaracionRentaSelecionados == null || this.tiposDeduccionDeclaracionRentaSelecionados.filter(item => item.seleccionado).length == 0) {
      this.lineaBase.lanzarMensajeError('El campo Tipo de deducción de la declaración de renta es obligatorio. Debe seleccionar una opción y marcarla en Si, la otra opción debe ser No. No podrá ser cambiado durante la ejecución del contrato.');
      esValido = false;
    }

    if (this.lineaBase.informeContratista.periodoPagoPlanilla == null) {
      this.lineaBase.lanzarMensajeError('El campo Periodo de pago es obligatorio');
      esValido = false;
    }
    return esValido;
  }

  /**
   * Metodo que valida y guardar la información de planilla (datos por defecto), y beneficios tributarios asociados en el informe al dar clic en el botón guardar
   */
  guardarBeneficiosTributarios() {
    if (!this.lineaBase.habilitaEdicion) {
      this.lineaBase.blockedDocument = true;

      if (this.validarBeneficiosTributarios()) {
        this.lineaBase.informeContratista.periodoPagoPlanilla = new Date(this.lineaBase.informeContratista.periodoPagoPlanilla);

        this.lineaBase.informeContratista.tieneDosOmasTrabajadores = this.contratoTrabajadores;
        this.lineaBase.informeContratista.idTipoDeduccionDeclaracionRenta = this.tiposDeduccionDeclaracionRentaSelecionados.find(item => item.seleccionado).id;

        if (this.lineaBase.esBorrador) {
          this.informeContratistaService.srvGuardarBeneficiosTributariosBorrador(this.lineaBase.informeContratista).subscribe(
            result => {
              if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                  this.lineaBase.lanzarMensajeInfo("La información se guardó exitosamente.");
                } else {
                  this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                }
              }
            },
            error => {
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
              this.lineaBase.blockedDocument = false;
            }
          );
        } else {
          this.informeContratistaService.srvGuardarBeneficiosTributarios(this.lineaBase.informeContratista).subscribe(
            result => {
              if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                  this.lineaBase.lanzarMensajeInfo("La información se guardó exitosamente.");
                } else {
                  this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                }
              }
            },
            error => {
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
              this.lineaBase.blockedDocument = false;
            }
          );
        }
      } else {
        this.lineaBase.blockedDocument = false;
      }
    }
  }

  //#region <Planilla>

  /**
   * Metodo que carga la información de la planilla en los campos del formulario
   */
  cargarInformePlanilla() {
    if (this.lineaBase.informeContratista != null) {
      this.contratoTrabajadores = this.lineaBase.informeContratista.tieneDosOmasTrabajadores;

      //Carga datos deducción de acuerdo al id seleccionado
      this.tiposDeduccionDeclaracionRentaSelecionados = this.lineaBase.tiposDeduccionDeclaracionRenta.map(
        item => item.id == this.lineaBase.informeContratista.idTipoDeduccionDeclaracionRenta ? { ...item, seleccionado: true } : { ...item, seleccionado: false });

      if (this.lineaBase.informeContratista.periodoPagoPlanilla != null) {
        this.lineaBase.informeContratista.periodoPagoPlanilla = new Date(this.lineaBase.informeContratista.periodoPagoPlanilla);
      }
      this.cargarAdjuntosPlanillaPorInforme(this.lineaBase.idInforme);
    }
  }

  /**
   * Metodo que inicializa las variables del formulario de adjuntos para la Planilla
   */
  irAgregarPlanilla() {
    this.visibleDialogoFormularioPlanilla = true;
    this.fechaPagoPlanilla = null;
    this.numeroPlanillaVar = null;
  }

  /**
   *  Metodo que valida que la información de la planilla contenga la información competa antes de guardarla
   * @returns Verdadero o Falso
   */
  validaInformacionPlanilla() {
    let esValido = true;
    if (this.fechaPagoPlanilla == null) {
      this.lineaBase.lanzarMensajeError("La fecha de pago es un campo obligatorio");
      esValido = false;
    }
    if (this.numeroPlanillaVar == null) {
      this.lineaBase.lanzarMensajeError("El número de planilla es un campo obligatorio");
      esValido = false;
    }
    if (this.archivos == null || this.archivos.length < 1) {
      this.lineaBase.lanzarMensajeError("El adjunto de la planilla es un campo obligatorio");
      esValido = false;
    }
    return esValido;
  }


  /**
   * Metodo que guarda la información de la planilla sin el adjunto, osea es el dialogo que solicita el numero y la fecha de pago de la planilla
   */
  guardarAdjuntoPlanilla() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoFormularioPlanilla = false;
    if (this.validaInformacionPlanilla()) {
      //Armar objeto de adjunto para guardar los campos de planilla
      this.construirAdjuntoInformePlanilla();
      const archivoAdjuntoPorPlanillaList = this.construirAdjuntoInformePlanilla();
      if (this.lineaBase.esBorrador) {
        this.informeContratistaService.srvGuardarAdjuntoInformeBorrador(archivoAdjuntoPorPlanillaList).subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.lineaBase.lanzarMensajeInfo("La planilla se guardó exitosamente.");
              } else {
                this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              }
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.lineaBase.blockedDocument = false;
            this.cargarAdjuntosPlanillaPorInforme(this.lineaBase.idInforme);
            this.archivos = [];
          }
        );
      } else {
        this.informeContratistaService.srvGuardarAdjuntoInforme(archivoAdjuntoPorPlanillaList).subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.lineaBase.lanzarMensajeInfo("La planilla se guardó exitosamente.");
              } else {
                this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              }
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.lineaBase.blockedDocument = false;
            this.cargarAdjuntosPlanillaPorInforme(this.lineaBase.idInforme);
            this.archivos = [];
          }
        );
      }
    }
  }

  /**
   * Metodo que consulta los adjuntos asociadas a la planilla a traves del informe en borrador o definitivo
   * @param id Id del Informe
   */
  cargarAdjuntosPlanillaPorInforme(id) {
    this.lineaBase.blockedDocument = true;
    this.adjuntosPlanillaList = [];
    forkJoin(
      this.informeContratistaService.getAdjuntosPorInforme(id, this.lineaBase.esBorrador)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.adjuntosPlanillaList = result.adjuntos;
          } else {
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que controla el evento de abrir el dialogo para la eliminacion de un adjunto de la Planilla e inicializa las variables 
   * @param _adjunto 
   */
  verDialogoEliminarAdjuntoPlanilla(_adjunto) {
    this.idAdjuntoEliminar = _adjunto.id;
    this.visibleDialogoEliminarAdjuntoPlanilla = true;
  }

  /**
   * Metodo que controla el evento de cierre del dialogo para la eliminacion de un adjunto de la Planilla
   */
  cancelarEliminarAdjuntoPlanilla() {
    this.visibleDialogoEliminarAdjuntoPlanilla = false;
  }

  /**
   * Metodo que controla el evento de cierre del dialogo para el cargue de adjuntos
   */
  cancelarDialogoFormularioPlanilla() {
    this.visibleDialogoFormularioPlanilla = false;
  }

  /**
   * Metodo que controla el evento al eliminar un adjunto de Planilla
   */
  aceptarEliminarAdjuntoPlanilla() {
    this.lineaBase.blockedDocument = true;
    if (this.lineaBase.esBorrador) {
      return this.informeContratistaService.srvEliminarAdjuntoInformeBorrador(this.idAdjuntoEliminar).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAdjuntosPlanillaPorInforme(this.lineaBase.idInforme);
          this.visibleDialogoEliminarAdjuntoPlanilla = false;
        }
      );
    } else {
      return this.informeContratistaService.srvEliminarAdjuntoInforme(this.idAdjuntoEliminar).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAdjuntosPlanillaPorInforme(this.lineaBase.idInforme);
          this.visibleDialogoEliminarAdjuntoPlanilla = false;
        }
      );
    }
  }
  //#endregion 


  //#region <Certificado de Cuenta AFC>  
  /**
   * Metodo que inicializa la ruta base del anexo en el blobstorage y la variable para abrir el dialogo que permite subir los adjuntos de AFC
   */
  irVerDialogoSubirAFC() {
    this.visibleDialogoSubirAFC = true;
    this.rutaCuentaAFC = this.rutaCloudStorageAnexos;
  }

  /**
  * Metodo que controla el evento de cierre del dialogo para subir documentos AFC
  */
  cancelarDialogoSubirAFC() {
    this.visibleDialogoSubirAFC = false;
  }

  /**
   * Metodo que controla el evento al adjuntar los archivos de Certificado de Cuenta AFC
   */
  aceptarAdjuntoAFC() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoSubirAFC = false;
    let anexo = this.construirAnexoInforme(this.idCuentaAFC, EnumCtoTipoAnexoInforme.CERTIFICACION_AFC, this.rutaCuentaAFC);
    let adjuntosList = [];

    forkJoin(
      //Obtiene los archivos adjuntos actuales para el anexo
      this.informeContratistaService.getAdjuntosPorAnexo(this.idCuentaAFC, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.adjuntosAnexosList = result.adjuntos;
          //Valida la cantidad de adjuntos del anexo (los que estoy subiendo + los que estan ya guardados)
          const tamanioFinalAdjuntos: number = this.archivos.length + this.adjuntosAnexosList.length;
          if (tamanioFinalAdjuntos <= this.cantidadMaximaAdjuntos) {
            if (this.lineaBase.esBorrador) {
              //Guarda el anexo relacionado sin el adjunto
              this.informeContratistaService.srvGuardarAnexoInformeBorrador(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCuentaAFC
                  this.idCuentaAFC = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);
                  //Servicio de guardar adjunto relacionando el anexo que se guardó antes
                  this.informeContratistaService.srvGuardarAdjuntoInformeBorrador(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    }
                  );
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                },
                error => {
                  this.principalComponent.cargarErrorServicio(error);
                }
              );
            } else {
              //Guarda el anexo relacionado sin el adjunto
              this.informeContratistaService.srvGuardarAnexoInforme(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCuentaAFC
                  this.idCuentaAFC = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);
                  //Servicio de guardar adjunto relacionando el anexo que se guardó antes
                  this.informeContratistaService.srvGuardarAdjuntoInforme(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    }
                  );
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                },
                error => {
                  this.principalComponent.cargarErrorServicio(error);
                }
              );
            }
          } else {
            this.lineaBase.lanzarMensajeError('Solo se permiten 3 archivos máximo por anexo.');
          }
        } else {
          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
        }
      }
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }
  //#endregion 

  //#region <Certificado Bancario>  
  /**
   * Metodo que inicializa la ruta base del anexo en el blobstorage y la variable para abrir el dialogo que permite subir los adjuntos de Certificado Bancario
   */
  irVerDialogoSubirBancaria() {
    this.visibleDialogoSubirBancaria = true;
    this.rutaCertificacionBancaria = this.lineaBase.vigencia + '/' + this.lineaBase.idContrato + '_' + this.lineaBase.numeroContrato + '/' + this.lineaBase.numeroDocumento + '/' + this.lineaBase.numeroPago + '/anexos/';
  }

  /**
  * Metodo que controla el evento de cierre del dialogo para subir documentos Certificado Bancario
  */
  cancelarDialogoSubirBancaria() {
    this.visibleDialogoSubirBancaria = false;
  }

  /**
   * Metodo que controla el evento al adjuntar los archivos de Certificado Certificado Bancario
   */
  aceptarAdjuntoBancaria() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoSubirBancaria = false;
    let anexo = this.construirAnexoInforme(this.idCertificacionBancaria, EnumCtoTipoAnexoInforme.CERTIFICACION_CREDITO_HITPOTECARIO, this.rutaCertificacionBancaria);
    let adjuntosList = [];

    setTimeout(() => {
      this.lineaBase.blockedDocument = false;
    }, 1000);

    forkJoin(
      this.informeContratistaService.getAdjuntosPorAnexo(this.idCertificacionBancaria, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.adjuntosAnexosList = result.adjuntos;
          //Valida la cantidad de adjuntos del anexo (los que estoy subiendo + los que estan ya guardados)
          const tamanioFinalAdjuntos: number = this.archivos.length + this.adjuntosAnexosList.length;
          if (tamanioFinalAdjuntos <= this.cantidadMaximaAdjuntos) {
            if (this.lineaBase.esBorrador) {
              this.informeContratistaService.srvGuardarAnexoInformeBorrador(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCertificacionBancaria
                  this.idCertificacionBancaria = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);
                  this.informeContratistaService.srvGuardarAdjuntoInformeBorrador(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    });
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                });
            } else {
              this.informeContratistaService.srvGuardarAnexoInforme(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCertificacionBancaria
                  this.idCertificacionBancaria = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);

                  this.informeContratistaService.srvGuardarAdjuntoInforme(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    }
                  );
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                },
                error => {
                  this.principalComponent.cargarErrorServicio(error);
                }
              );
            }
          } else {
            this.lineaBase.lanzarMensajeError('Solo se permiten 3 archivos máximo por anexo.');
          }
        } else {
          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
        }
      }
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }
  //#endregion 

  //#region <Certificado Dependientes>  
  /**
   * Metodo que inicializa la ruta base del anexo en el blobstorage y la variable para abrir el dialogo que permite subir los adjuntos de Certificado de Dependientes
   */
  irVerDialogoSubirDependientes() {
    this.visibleDialogoSubirDependientes = true;
    this.rutaCertificacionDependientes = this.lineaBase.vigencia + '/' + this.lineaBase.idContrato + '_' + this.lineaBase.numeroContrato + '/' + this.lineaBase.numeroDocumento + '/' + this.lineaBase.numeroPago + '/anexos/';
  }

  /**
   * Metodo que controla el evento de cierre del dialogo para subir documentos Certificado Dependientes
   */
  cancelarDialogoSubirDependientes() {
    this.visibleDialogoSubirDependientes = false;
  }

  /**
   * Metodo que controla el evento al adjuntar los archivos de Certificado Certificado Dependientes
   */
  aceptarAdjuntoDependientes() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoSubirDependientes = false;
    let anexo = this.construirAnexoInforme(this.idCertificacionDependientes, EnumCtoTipoAnexoInforme.CERTIFICADO_DEPENDIENTES, this.rutaCertificacionDependientes);
    let adjuntosList = [];
    forkJoin(
      this.informeContratistaService.getAdjuntosPorAnexo(this.idCertificacionDependientes, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {

        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.adjuntosAnexosList = result.adjuntos;
          //Valida la cantidad de adjuntos del anexo (los que estoy subiendo + los que estan ya guardados)
          const tamanioFinalAdjuntos: number = this.archivos.length + this.adjuntosAnexosList.length;
          if (tamanioFinalAdjuntos <= this.cantidadMaximaAdjuntos) {
            if (this.lineaBase.esBorrador) {
              //Guarda el anexo relacionado sin el adjunto
              this.informeContratistaService.srvGuardarAnexoInformeBorrador(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCertificacionDependientes
                  this.idCertificacionDependientes = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);

                  this.informeContratistaService.srvGuardarAdjuntoInformeBorrador(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    }
                  );
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                },
                error => {
                  this.principalComponent.cargarErrorServicio(error);
                }
              );
            } else {
              this.informeContratistaService.srvGuardarAnexoInforme(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCertificacionDependientes
                  this.idCertificacionDependientes = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);
                  this.informeContratistaService.srvGuardarAdjuntoInforme(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    }
                  );
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                },
                error => {
                  this.principalComponent.cargarErrorServicio(error);
                }
              );
            }
          } else {
            this.lineaBase.lanzarMensajeError('Solo se permiten 3 archivos máximo por anexo.');
          }
        } else {
          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
        }
      }
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }
  //#endregion 

  //#region <Certificado Medicina Prepagada>  
  /**
   * Metodo que inicializa la ruta base del anexo en el blobstorage y la variable para abrir el dialogo que permite subir los adjuntos de Certificado de Medicina Prepagada
   */
  irVerDialogoSubirPrepagada() {
    this.visibleDialogoSubirMedicinaPrepagada = true;
    this.rutaCertificacionMediciaPrepagada = this.lineaBase.vigencia + '/' + this.lineaBase.idContrato + '_' + this.lineaBase.numeroContrato + '/' + this.lineaBase.numeroDocumento + '/' + this.lineaBase.numeroPago + '/anexos/';
  }

  /**
   *  Metodo que controla el evento de cierre del dialogo para subir documentos Certificado de Medicina Prepagada
   */
  cancelarDialogoSubirPrepagada() {
    this.visibleDialogoSubirMedicinaPrepagada = false;
  }

  /**
   * Metodo que controla el evento al adjuntar los archivos de Certificado Certificado de Medicina Prepagada
   */
  aceptarAdjuntoPrepagada() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoSubirMedicinaPrepagada = false;
    let anexo = this.construirAnexoInforme(this.idCertificacionMediciaPrepagada, EnumCtoTipoAnexoInforme.CERTIFICADO_MEDICINA_PREPAGADA, this.rutaCertificacionMediciaPrepagada);
    let adjuntosList = [];

    forkJoin(
      this.informeContratistaService.getAdjuntosPorAnexo(this.idCertificacionMediciaPrepagada, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.adjuntosAnexosList = result.adjuntos;
          //Valida la cantidad de adjuntos del anexo (los que estoy subiendo + los que estan ya guardados)
          const tamanioFinalAdjuntos: number = this.archivos.length + this.adjuntosAnexosList.length;
          if (tamanioFinalAdjuntos <= this.cantidadMaximaAdjuntos) {
            if (this.lineaBase.esBorrador) {
              //Guarda el anexo relacionado sin el adjunto
              this.informeContratistaService.srvGuardarAnexoInformeBorrador(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCertificacionMediciaPrepagada
                  this.idCertificacionMediciaPrepagada = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);

                  this.informeContratistaService.srvGuardarAdjuntoInformeBorrador(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    }
                  );
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                },
                error => {
                  this.principalComponent.cargarErrorServicio(error);
                }
              );
            } else {
              this.informeContratistaService.srvGuardarAnexoInforme(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCertificacionMediciaPrepagada
                  this.idCertificacionMediciaPrepagada = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);

                  this.informeContratistaService.srvGuardarAdjuntoInforme(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    }
                  );
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                },
                error => {
                  this.principalComponent.cargarErrorServicio(error);
                }
              );
            }
          } else {
            this.lineaBase.lanzarMensajeError('Solo se permiten 3 archivos máximo por anexo.');
          }
        } else {
          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
        }
      }
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }
  //#endregion 

  //#region <Certificado Pensiones Voluntarias>  
  /**
   * Metodo que inicializa la ruta base del anexo en el blobstorage y la variable para abrir el dialogo que permite subir los adjuntos de Certificado de Medicina Prepagada
   */
  irVerDialogoSubirVoluntaria() {
    this.visibleDialogoSubirPensionVoluntaria = true;
    this.rutaCertificacionPensionVoluntaria = this.lineaBase.vigencia + '/' + this.lineaBase.idContrato + '_' + this.lineaBase.numeroContrato + '/' + this.lineaBase.numeroDocumento + '/' + this.lineaBase.numeroPago + '/anexos/';
  }

  /**
   *  Metodo que controla el evento de cierre del dialogo para subir documentos Certificado Pensiones Voluntarias
   */
  cancelarDialogoSubirVoluntaria() {
    this.visibleDialogoSubirPensionVoluntaria = false;
  }

  aceptarAdjuntoVoluntaria() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoSubirPensionVoluntaria = false;
    let anexo = this.construirAnexoInforme(this.idCertificacionPensionVoluntaria, EnumCtoTipoAnexoInforme.CERTIFICADO_PENSIONES_VOLUNTARIAS, this.rutaCertificacionPensionVoluntaria);
    let adjuntosList = [];

    forkJoin(
      this.informeContratistaService.getAdjuntosPorAnexo(this.idCertificacionPensionVoluntaria, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.adjuntosAnexosList = result.adjuntos;
          //Valida la cantidad de adjuntos del anexo (los que estoy subiendo + los que estan ya guardados)
          const tamanioFinalAdjuntos: number = this.archivos.length + this.adjuntosAnexosList.length;
          if (tamanioFinalAdjuntos <= this.cantidadMaximaAdjuntos) {
            if (this.lineaBase.esBorrador) {
              //Guarda el anexo relacionado sin el adjunto
              this.informeContratistaService.srvGuardarAnexoInformeBorrador(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCertificacionPensionVoluntaria
                  this.idCertificacionPensionVoluntaria = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);

                  this.informeContratistaService.srvGuardarAdjuntoInformeBorrador(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    }
                  );
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                },
                error => {
                  this.principalComponent.cargarErrorServicio(error);
                }
              );
            } else {
              this.informeContratistaService.srvGuardarAnexoInforme(anexo).subscribe(
                result => {
                  anexo = result;
                  //Obtiene el anexo guardado para relacionarlo con los archivos que se quieren guardar y actualizar el objeto idCertificacionPensionVoluntaria
                  this.idCertificacionPensionVoluntaria = anexo.id;
                  adjuntosList = this.construirAdjuntoInformePorAnexo(anexo);

                  this.informeContratistaService.srvGuardarAdjuntoInforme(adjuntosList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('El anexo se guardó exitosamente.');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    }
                  );
                  this.archivos = [];
                  this.lineaBase.blockedDocument = false;
                },
                error => {
                  this.principalComponent.cargarErrorServicio(error);
                }
              );
            }
          } else {
            this.lineaBase.lanzarMensajeError('Solo se permiten 3 archivos máximo por anexo.');
          }
        } else {
          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
        }
      }
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }
  //#endregion 

  //#region <Metodos genericos de anexos y adjuntos>  


  /**
   * Metodo para constuir el objeto de adjuntos asociados a la planilla   
   * @returns Listado de adjuntos para guardar de la planilla
   */
  construirAdjuntoInformePlanilla() {
    //Armar objeto de adjunto para guardar los campos de planilla
    const archivoAdjuntoPorPlanillaList: CtoAdjuntoInformeBorradorDTO[] = [];

    //No se requerie un listado dado que la planilla solo permite un archivo a la vez
    const adjuntoPlanilla = {
      idInforme: this.lineaBase.idInforme,
      nombreArchivoUsuario: this.archivos[0].nombre,
      rutaSoporte: this.archivos[0].nombreCompleto,
      fechaPagoPlanilla: new Date(this.fechaPagoPlanilla),
      numeroPlanilla: this.numeroPlanillaVar
    }
    archivoAdjuntoPorPlanillaList.push(adjuntoPlanilla);
    return archivoAdjuntoPorPlanillaList;
  }


  /**
   * Metodo para contruir el objeto de Anexo que sera utilizado para guardar los anexos y sus adjuntos
   * @param idAnexo Id del anexo que  ya se encuentra guardado
   * @param idTipoAnexo Id del tipo Anexo
   * @param rutaSoporteBase Ruta base donde quedará guardado el adjunto 
   * @returns Anexo de tipo CtoAnexoInformeContratistaDTO
   */
  construirAnexoInforme(idAnexo: number, idTipoAnexo: number, rutaSoporteBase: string) {
    const anexo: CtoAnexoInformeContratistaDTO = {};
    anexo.id = idAnexo;
    anexo.idTipoAnexo = idTipoAnexo;
    anexo.rutaSoporte = rutaSoporteBase;
    anexo.idInforme = this.lineaBase.idInforme;
    return anexo;
  }

  /**
   * Metodo para constuir el objeto de adjuntos asociados a un anexo que será guardado
   * @param anexo Objeto de anexo guardado para relacionarlo a los adjuntos
   * @returns Listado de adjuntos para guardar
   */
  construirAdjuntoInformePorAnexo(anexo: CtoAnexoInformeContratistaDTO) {
    const adjuntosBorradorList: CtoAdjuntoInformeBorradorDTO[] = [];

    //Arreglo de adjuntos subidos
    this.archivos.forEach(arc => {
      const adjunto: CtoAdjuntoInformeBorradorDTO = {
        idAnexo: anexo.id,
        nombreArchivoUsuario: arc.nombre,
        rutaSoporte: arc.nombreCompleto,
      }
      adjuntosBorradorList.push(adjunto);
    });

    return adjuntosBorradorList;
  }

  /**
    * Metodo que controla el evento para abrir del dialogo que muestra los adjuntos de un enexo 
    */
  irVerDialogoVerAdjuntosAnexos(_idAnexo) {
    this.visibleDialogoVerAdjuntosAnexos = true;
    this.idAnexo = _idAnexo;
    this.cargarAdjuntosPorAnexo(this.idAnexo);
  }

  /**
   * Metodo que llama el servicio de consulta de un anexo de un informe en borrador o definitivo dado su idAnexo
   */
  cargarAdjuntosPorAnexo(idAnexo) {
    this.adjuntosAnexosList = [];
    forkJoin(
      this.informeContratistaService.getAdjuntosPorAnexo(idAnexo, this.lineaBase.esBorrador)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.adjuntosAnexosList = result.adjuntos;
          } else {
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.lineaBase.blockedDocument = false;
      }
    );
  }

  /**
    * Metodo que controla el evento para abrir del dialogo para eliminar un adjunto e inicializa las variables 
    */
  verDialogoEliminarAdjunto(_adjunto) {
    this.idAdjuntoEliminarAnexo = _adjunto.id;
    this.visibleDialogoEliminarAdjunto = true;
  }

  /**
   * Metodo que controla el evento de cierre del dialogo e inicializa las variables del formulario de listado de adjuntos
   */
  cancelarDialogoVerAdjunto() {
    this.visibleDialogoVerAdjuntosAnexos = false;
    this.adjuntosAnexosList = [];
  }

  /**
  * Metodo que controla el evento de cierre del dialogo para eliminar un adjunto
  */
  cancelarEliminarAdjunto() {
    this.visibleDialogoEliminarAdjunto = false;
  }

  /**
   * Metodo que controla el evento para eliminar un adjunto asociada al informe en borrador o definitivo dado su id
   * @returns Resultado de la operación
   */
  aceptarEliminarAdjunto() {
    if (this.lineaBase.esBorrador) {
      return this.informeContratistaService.srvEliminarAdjuntoInformeBorrador(this.idAdjuntoEliminarAnexo).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAdjuntosPorAnexo(this.idAnexo);
          this.visibleDialogoEliminarAdjunto = false;
        }
      );
    } else {
      return this.informeContratistaService.srvEliminarAdjuntoInforme(this.idAdjuntoEliminarAnexo).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAdjuntosPorAnexo(this.idAnexo);
          this.visibleDialogoEliminarAdjunto = false;
        }
      );
    }
  }

  /**
  * Metodo que controla el evento para abrir del dialogo para eliminar un anexo e inicializa las variables 
  */
  verDialogoEliminarAnexo(idAnexo: number) {
    if (idAnexo != null && idAnexo > 0) {
      this.idAnexoEliminar = idAnexo;
      this.visibleDialogoEliminarAnexo = true;
    }
  }

  /**
  * Metodo que controla el evento de cierre del dialogo para eliminar un anexo
  */
  cancelarDialogoEliminarAnexo() {
    this.visibleDialogoEliminarAnexo = false;
  }

  /**
   * Metodo que controla el evento para eliminar un anexo y sus adjuntos asociada al informe en borrador o definitivo dado su id
   * @returns Resultado de la operación
   */
  aceptarEliminarAnexo() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoEliminarAnexo = false;
    if (this.lineaBase.esBorrador) {
      return this.informeContratistaService.srvEliminarAnexoInformeBorrador(this.idAnexoEliminar).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El anexo se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeInfo('Ocurió un error al eliminar el anexo intente nuevamente.');
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAnexosTributariosPorInforme(this.lineaBase.idInforme);
          this.visibleDialogoEliminarAnexo = false;
          this.lineaBase.blockedDocument = false;
        }
      );
    } else {
      return this.informeContratistaService.srvEliminarAnexoInforme(this.idAnexoEliminar).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El anexo se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeInfo('Ocurió un error al eliminar el anexo intente nuevamente.');
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAnexosTributariosPorInforme(this.lineaBase.idInforme);
          this.visibleDialogoEliminarAnexo = false;
          this.lineaBase.blockedDocument = false;
        }
      );
    }
  }
  //#endregion 

}
