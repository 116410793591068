import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { NovedadesConstractualesService } from '../../novedades-contractuales.service';
import { CtoNovedadContratoDTO } from '../../../../contratacion-dto/cto-novedad-contrato';
import { CtoContratoInformacionVigenteEncabezadoDTO } from '../../../../contratacion-dto/contrato-informacion-vigente-encabezado';
import { EnumFechaFormato, EnumRespuestaServicio, EnumTrnConstante } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumCtoTipoNovedadContrato } from 'src/app/contratacion/constantes/contratacion-constantes';
import { CtoTipoNovedadDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-novedad';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { TrnConstantesService } from 'src/app/comun/constantes/trn-constantes.service';
import { forkJoin, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
@Component({
  selector: 'app-novedad-contrato-base',
  templateUrl: './novedad-contrato-base.component.html',
  styles: []
})
export class NovedadContratoBaseComponent implements OnInit {

  idNovedad: number;
  novedadContrato: CtoNovedadContratoDTO = {};
  idContrato: number;
  ctoContratoInformacionVigenteBase: CtoContratoInformacionVigenteEncabezadoDTO;
  blockedDocument = false;
  tipoNovedadBase: CtoTipoNovedadDTO;

  visibleDialogoAgregarRegistro = false;
  visibleDialogoExito = false;
  visibleDialogoError = false;
  mensajeError: string;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;
  visibleDialogoErrorServicio = false;


  mapConstantes;
  fechaMaxCalendarioNovedad: Date;
  diasHabilitaFechaMaxCalendario = 90; //por defecto se deja 90 días

  msgValFechaSuscripcionMayorFechaFinContrato = 'La fecha de suscripción de la novedad no puede ser mayor a la fecha fin del contrato';
  msgValFechaSuscripcionMenorFechaInicialContrato = 'La fecha de suscripción de la novedad no puede ser menor a la fecha inicial del contrato';
  msgValFechaAplicacionMayorFechaFinContrato = 'La fecha de aplicación de la novedad no puede ser mayor a la fecha fin del contrato';
  msgValFechaAplicacionMenorFechaInicialContrato = 'La fecha de aplicación de la novedad no puede ser menor a la fecha inicial del contrato';
  msgValFechaAplicacionMenorFechaSuscripcion = 'La fecha de aplicación de la novedad debe ser mayor o igual a la fecha de suscripción de la novedad';
  msgValPlazosNulos = 'Debe indicar los plazos de la novedad, ya sea en años, meses y/o días. En caso de que no aplique digite 0';
  msgValPlazosEnCero = 'Debe indicar los plazos de la novedad, ya sea en años, meses y/o días. Al menos un plazo debe ser diferente de 0';
  msgValFuentesFinanciacionVacia = 'No ha agregado las fuentes de financiación para esta novedad';
  msgValFuentesFinanciacionANIVacia = 'No está asociando la fuente ANI teniendo en cuenta la imputación de recursos seleccionada';
  msgValFuentesFinanciacionFIDUCIASVacia = 'No está asociando la(s) fuente(s) FIDUCIAS teniendo en cuenta la imputación de recursos seleccionada';
  msgValFuentesFinanciacionMIXTASVacia = 'No está asociando la(s) fuente(s) ANI - FIDUCIAS teniendo en cuenta la imputación de recursos seleccionada';
  msgValCesionAsociarContratistaNulo = 'No está asociando el contratista (Cesionario) al cual se le realiza la cesión del contrato';
  msgValCesionAsociarMismoContratista = 'El contratista no puede ser el mismo que esta asociado actualmente al contrato';
  msgValCesionEsResponsableIvaNulo = 'No está diligenciado el campo "Es responsable de IVA"';
  msgValCesionEstaObligadoFacturarNulo = 'No está diligenciado el campo "Está Obligado(a) a facturar electrónicamente"';
  msgRecuerdeTiempoEjecucionContrato = 'La fecha de terminación del contrato estimada se calcula automáticamente de acuerdo a los Plazos (años, meses y días)';
  msgRecuerdeReduccionTiempoEjecucionContrato = 'Diligencie el plazo en años, meses y días en que se REDUCIRÁ el contrato, el sistema calculará automáticamente la nueva fecha de terminación del contrato.';
  msgRecuerdeReduccionTiempoEjecucionContratoFecha = 'Recuerde, esta opción se utiliza solo sí no se cumple que cada periodo sea de 30 días calendario o que se requiere que el contrato termine un 31 del mes por excepciones particulares, por lo tanto no es posible definirlo en cuestión de plazos (años, meses y días).';
  msgValNuevaFechaFinMenorFechaFinActualContrato = 'La nueva fecha de terminación del contrato no puede ser menor o igual a la fecha actual de terminación del contrato';
  msgValNuevaFechaFinMenorFechaInicioActualContrato = 'La nueva fecha de terminación del contrato no puede ser menor o igual a la fecha actual de inicio del contrato';

  constructor(private messageService: MessageService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private contratacionService: ContratacionService,
    private constantesService: TrnConstantesService,
    private novedadesConstractualesService: NovedadesConstractualesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.mapConstantes = new Map();
    this.fechaMaxCalendarioNovedad = new Date();

    this.activateRoute.params.subscribe(params => {
      this.idContrato = params.idContrato;
      this.idNovedad = params.idNovedad;

      if (params.idTipoNovedad != null && Number(params.idTipoNovedad) != 0) {
        this.cargarTipoNovedad(params.idTipoNovedad);
      }

      if (this.idNovedad != null && this.idNovedad != 0) {
        this.consultarNovedad();
      } else {
        this.novedadContrato.id = 0;
        // this.novedadContrato.idTipoNovedad = this.tipoNovedadBase.id;
        // this.novedadContrato.tipoNovedad = this.tipoNovedadBase.nombre;
        this.novedadContrato.idContrato = this.idContrato;
        this.novedadContrato.habilitarEdicion = true;
      }

      this.cargarDatosIniciales();

    });
  }

  /**
   * Carga los datos iniciales necesarios para el componente.
   * 
   * Este método bloquea el documento mientras se cargan las constantes de novedades
   * y luego establece la fecha máxima del calendario de novedades basada en la fecha
   * de terminación del contrato vigente.
   * 
   * @returns {void}
   */
  cargarDatosIniciales() {
    forkJoin([
      this.cargarConstantesNovedades(),
    ]).subscribe(
      ([constantesResult]) => {
        if (constantesResult.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          if (!this.ctoContratoInformacionVigenteBase || !this.ctoContratoInformacionVigenteBase.fechaTerminacionContrato) { // El valor es null, undefined, o falsy (como una cadena vacía)
            //Por defecto se limita a la fecha actual + los dias de la constante 
            const fechaMax = this.addDays(new Date(), this.diasHabilitaFechaMaxCalendario);
            this.novedadContrato.fechaMaximaCalendarioNovedad = fechaMax;
          } else {
            //Se limita a la fecha de terminacion + los dias de la constante 
            const fechaMax = this.addDays(this.ctoContratoInformacionVigenteBase.fechaTerminacionContrato, this.diasHabilitaFechaMaxCalendario);
            this.novedadContrato.fechaMaximaCalendarioNovedad = fechaMax;
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }


  /**
   * Metodo que carga y modifica el objeto del componente generico del contrato vigente para ser utilizado en el registro de las novedades
   * @param contrato Objeto de tipo CtoContratoInformacionVigenteEncabezadoDTO cobn la información vigente del contrato
   */
  actualizarContratoInformacionVigente(contrato: CtoContratoInformacionVigenteEncabezadoDTO) {
    this.ctoContratoInformacionVigenteBase = contrato;
    this.ctoContratoInformacionVigenteBase.fechainicio = (new Date(this.ctoContratoInformacionVigenteBase.fechainicio));
    this.ctoContratoInformacionVigenteBase.fechaTerminacionContrato = (new Date(this.ctoContratoInformacionVigenteBase.fechaTerminacionContrato));
  }



  /**
   * Metodo que llama al servicio de constantes para validar los días que se deben sumar a la fecha de terminación de contrato para usarlo como maxDate de las fechas utilizadas en cada novedades contractual
   */
  cargarConstantesNovedades() {
    const nombresConstantes = `${EnumTrnConstante.CTO_NOVEDADES_CONTRACTUALES_DIAS_HABILITA_FECHA_MAX_CALENDARIO}`;
    return this.constantesService.srvGetConstantesListaPorNombre(nombresConstantes)
      .pipe(
        tap(result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.mapConstantes = new Map();
              result.constantesLista.forEach(cns => {
                this.mapConstantes.set(cns.nombre, cns.valor);
              });
              //Obtiene el valor de la constante requerida sino se puede consutlar la constante por defecto se asigna 0              
              this.diasHabilitaFechaMaxCalendario = Number(this.mapConstantes.get(EnumTrnConstante.CTO_NOVEDADES_CONTRACTUALES_DIAS_HABILITA_FECHA_MAX_CALENDARIO)) || 90;
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        }),
        catchError(error => {
          this.principalComponent.cargarErrorServicio(error);
          return throwError(error);
        })
      );
  }


  /**
   * Metodo que agrega días a una fecha
   * @param date Fecha
   * @param days Cantidad días
   * @returns Fecha con los días adicionados
   */
  addDays(date: Date, days: number): Date {
    const fechaResultado: Date = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
    return fechaResultado;
  }


  /**
   * Metodo que resta días a una fecha
   * @param date Fecha
   * @param days Cantidad días
   * @returns Fecha con los días adicionados
   */
  subtractDays(date: Date, days: number): Date {
    const result = new Date(date);
    const milisegundosPorDia: number = 24 * 60 * 60 * 1000;
    return new Date(result.getTime() - days * milisegundosPorDia);
  }

  /**
  * Metodo que carga la información del contato cuando es un borrador
  * @param idContrato Id del contrato
  * @param esBorrador 1 sí es un contrato en borrador, 0 en caso contario
  */
  cargarTipoNovedad(idTipoNovedad: number) {
    this.blockedDocument = true;
    this.contratacionService.getCtoTipoNovedadPorId(idTipoNovedad)
      .subscribe(
        result => {
          if (result != null) {
            this.tipoNovedadBase = result;
            this.novedadContrato.idTipoNovedad = this.tipoNovedadBase.id;
            this.novedadContrato.tipoNovedad = this.tipoNovedadBase.nombre;
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  consultarNovedad() {
    this.novedadesConstractualesService.ConsultarNovedad(this.idNovedad).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.novedadContrato = result.novedadContrato;
            if (this.novedadContrato != null) {
              if (this.novedadContrato.fechaSuscripcion != null) {
                this.novedadContrato.fechaSuscripcion = new Date(this.novedadContrato.fechaSuscripcion);
              }
              if (this.novedadContrato.fechaAplicacion != null) {
                this.novedadContrato.fechaAplicacion = new Date(this.novedadContrato.fechaAplicacion);
              }
              if (this.novedadContrato.nuevaFechaTerminacion != null) {
                this.novedadContrato.nuevaFechaTerminacion = new Date(this.novedadContrato.nuevaFechaTerminacion);
              }
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  guardarNovedad(novedad: CtoNovedadContratoDTO) {
    let idNovedadGuardada = 0;
    this.blockedDocument = true;
    if (novedad != null) {
      this.novedadesConstractualesService.srvGuardarNovedad(novedad)
        .subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                idNovedadGuardada = result.idNovedad;
                this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
                this.visibleDialogoExito = true;
              } else {
                idNovedadGuardada = result.idNovedad;
                this.mensajeError = result.respuestaServicio.mensajeSalida;
                this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                this.visibleDialogoError = true;
              }
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
            this.blockedDocument = false;
          },
          () => {
            this.blockedDocument = false;
          }
        );
    }
  }

  get EnumCtoTipoNovedadContrato() {
    return EnumCtoTipoNovedadContrato;
  }

  get EnumFechaFormato() {
    return EnumFechaFormato;
  }

  aceptarDialogoExito() {
    this.irHistoricoNovedadesPorContrato();
  }

  irHistoricoNovedadesPorContrato() {
    this.router.navigate(['/contratosHome/novedadesContractuales/novedadesContrato/', this.ctoContratoInformacionVigenteBase.id]);
  }



  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.messageService.add({ severity: "error", summary: "Error", detail: mensaje, life: 10000, });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }



}
