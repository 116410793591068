import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { CtoAdjuntoInformeBorradorDTO, CtoAdjuntoInformeBorradorRpt } from '../../../contratacion-dto/adjunto-informe-borrador';
import { CtoAnexoInformeContratistaDTO, CtoAnexoInformeContratistaRpt } from '../../../contratacion-dto/anexo-informe-contratista';
import { CtoContratoPlanPagoVigenteRpt } from 'src/app/contratacion/contratacion-dto/contrato-plan-pago-vigente';
import { DatePipe } from '@angular/common';
import { TrnRespuestaServicioRpt } from '../../../../parametricas/rpt-respuesta-operacion';
import { CtoInformeContratistaCopiarInformeRpt, CtoInformeContratistaEnviarInformeRpt, RpUspCtoFinalizaInformeContratistaBorrador } from '../../../contratacion-dto/informe-contratista-validacion-informe';
import { CtoInformeContratistaDTO, CtoInformeContratistaRpt } from 'src/app/contratacion/contratacion-dto/informe-contratista';
import { CtoMaAnexoInformeDTO, CtoMaAnexoInformeRpt } from 'src/app/contratacion/contratacion-dto/cto-ma-tipo-anexo-informe';
import { CtoInformeObservacionRptDTO } from 'src/app/contratacion/contratacion-dto/cto-Informe-observacion';
import { CtoInformeRevisionActividadesRpt } from '../../../contratacion-dto/informe-revision-actividad';
import { CtoMaTipoDeduccionDeclaracionRentaRptDTO } from 'src/app/contratacion/contratacion-dto/cto-ma-tipo-deduccion-declaracion-renta';


@Injectable({
    providedIn: 'root'
})
export class InformeContratistaService {

    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
        public datepipe: DatePipe
    ) {
        this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo para consultar el plan de pagos de un contratista y contrato
     * @param idContrato Id del contrato
     * @param idPersona Id del contratista
     * @returns Objeto de respuesta CtoContratoPlanPagoVigenteRpt con el listado de pagos del contrato y el contratista
     */
    public srvGetPlanPagosContratoContratista(idContrato: number, idPersona: number): Observable<CtoContratoPlanPagoVigenteRpt> {
        return this.http.get<CtoContratoPlanPagoVigenteRpt>(this.END_POINT + '/CtoInformeContratista/getPlanPagosContratoContratista/' + idContrato + '/' + idPersona)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo para consultar el periodo de pago del plan de pagos para un informe que este tramitando
     * @param idContrato Id del contrato
     * @param idPersona Id del contratista
     * @param idPlanPago Id del Periodo de pago
     * @returns Objeto de respuesta CtoContratoPlanPagoVigenteRpt con el periodo de pago consultado</returns>
     */
    public srvGetPeriodoPagoContratoContratistaInforme(idContrato: number, idPersona: number, idPlanPago: number): Observable<CtoContratoPlanPagoVigenteRpt> {
        return this.http.get<CtoContratoPlanPagoVigenteRpt>(this.END_POINT + '/CtoInformeContratista/getPeriodoPagoContratoContratistaInforme/' + idContrato + '/' + idPersona + '/' + idPlanPago)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que consulta el informe contratista por id ya sea en borrador o definitivo
     * @param idInforme Id Informe Contratista
     * @param esBorrador Variable que determina si se consulta el borrador o el definitivo
     * @returns Objeto de tipo CtoInformeContratistaDTO
     */
    public srvGetInformeContratista(idInforme: number, esBorrador): Observable<CtoInformeContratistaRpt> {
        return this.http.get<CtoInformeContratistaRpt>(this.END_POINT + '/CtoInformeContratista/GetCtoInformeContratista/' + idInforme + '/' + esBorrador)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que consulta los Anexos adicionales de un informe por id  ya sea en borrador o definitivo
     * @param idInforme Id Informe Contratista
     * @param esBorrador Variable que determina si se consulta el borrador o el definitivo
     * @returns Objeto de tipo CtoAnexoInformeContratistaDTO
     */
    public srvGetAnexosAdicionalesPorInforme(idInforme: number, esBorrador): Observable<CtoAnexoInformeContratistaRpt> {
        return this.http.get<CtoAnexoInformeContratistaRpt>(this.END_POINT + '/CtoInformeContratista/getAnexosAdicionalesPorInforme/' + idInforme + '/' + esBorrador)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio de consulta de Anexos de informe contratista de acuerdo a los parametros
     * @param idsSubTipoAnexos EnumCtoTipoAnexoInforme.SUBTIPO, pueden ir separados por coma en caso de ser más de uno
     * @param idInforme Id del informe
     * @param esBorrador Varialbes que indica si el informe esta en borrador (true) o es definitivo (false)
     * @returns Objeto de Respuesta ConsultarAnexosRpt
     */
    public srvGetAnexosPorSubTipoAnexoInforme(idsSubTipoAnexos: string, idInforme: number, esBorrador): Observable<CtoAnexoInformeContratistaRpt> {
        return this.http.get<CtoAnexoInformeContratistaRpt>(this.END_POINT + '/CtoInformeContratista/GetAnexosPorSubTipoInforme/' + idsSubTipoAnexos + '/' + idInforme + '/' + esBorrador)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public srvGetMaTiposAnexosPersonaNatural(): Observable<CtoMaAnexoInformeRpt> {
        return this.http.get(this.END_POINT + '/CtoInformeContratista/getTiposAnexosPersonaNatural')
            .pipe(
                retry(1));
    }

    /**
     * Metodo que llama al servicio de guardar un informe en borrador
     * @param data 
     * @returns Objeto de respuesta CtoInformeContratistaRpt
     */
    public srvGuardarInformeContratistaBorrador(data): Observable<CtoInformeContratistaRpt> {
        return this.http.post<CtoInformeContratistaRpt>(this.END_POINT + '/CtoInformeContratista/GuardarInformeBorrador', data)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
      * Metodo que llama el servicio de cargar las obligaciones contractuales versus las actividades relacionadas en el informe
      * @param idContrato Id del Contrato
      * @param idInforme Id del Informe
      * @returns Listado de obligaciones contractuales relacionadas con las actividades
      */
    public srvGetActividadObligacion(idContrato, idInforme, esBorrador): Observable<any> {
        return this.http.get(this.END_POINT + '/CtoInformeContratista/getActividadObligacion/' + idContrato + '/' + idInforme + '/' + esBorrador)
            .pipe(
                retry(1));
    }

    /**
     * Metodo para guardar las actividades de las obligaciones contractuales para el informe en borrador
     * @param data 
     * @returns Resultado de la operación
     */
    public srvGuardarActividadObligacionBorrador(data): Observable<TrnRespuestaServicioRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarActividadObligacionBorrador', data)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que llama al servicio para guardar los adjuntos relaciondos con el Informe en borrador
     * @param data 
     * @returns Resultado de la operación
     */
    public srvGuardarAdjuntoInformeBorrador(data): Observable<TrnRespuestaServicioRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarAdjuntoInformeBorrador', data)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que llama al servicio para guardar los adjuntos relaciondos con el Informe en borrador
     * @param data Objeto de tipo CtoAdjuntoInformeBorradorDTO
     * @returns Resultado de la operación
     */
    public srvGuardarAdjuntoInforme(data): Observable<TrnRespuestaServicioRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarAdjuntoInforme', data)
            .pipe(
                retry(1));
    }

    public getAdjuntosPorObligacion(idActividad, borrador): Observable<CtoAdjuntoInformeBorradorRpt> {
        if (idActividad == null) {
            idActividad = 0;
        }
        return this.http.get(this.END_POINT + '/CtoInformeContratista/getAdjuntosPorObligacion/' + idActividad + '/' + borrador)
            .pipe(
                retry(1));
    }

    public getAdjuntosPorInforme(idInforme, borrador): Observable<CtoAdjuntoInformeBorradorRpt> {
        if (idInforme == null) {
            idInforme = 0;
        }
        return this.http.get(this.END_POINT + '/CtoInformeContratista/getAdjuntosPorInforme/' + idInforme + '/' + borrador)
            .pipe(
                retry(1));
    }

    public getAdjuntosPorAnexo(idAnexo, borrador): Observable<CtoAdjuntoInformeBorradorRpt> {
        if (idAnexo == null) {
            idAnexo = 0;
        }
        return this.http.get<CtoAdjuntoInformeBorradorRpt>(this.END_POINT + '/CtoInformeContratista/getAdjuntosPorAnexo/' + idAnexo + '/' + borrador)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo para eliminar un anexo y sus adjuntos asociada al informe en borrador dado su id
     * @param id Id del Anexo
     * @returns Resultado de la operación
     */
    public srvEliminarAnexoInformeBorrador(id): Observable<TrnRespuestaServicioRpt> {
        const url = this.END_POINT + '/CtoInformeContratista/eliminarAnexoInformeBorrador/' + id;
        return this.http.delete(url)
            .pipe(
                retry(1));
    }

    /**
     * Metodo para eliminar un anexo y sus adjuntos asociada al informe definitivo dado su id
     * @param id Id del Anexo
     * @returns Resultado de la operación
     */
    public srvEliminarAnexoInforme(id): Observable<TrnRespuestaServicioRpt> {
        const url = this.END_POINT + '/CtoInformeContratista/eliminarAnexoInforme/' + id;
        return this.http.delete(url)
            .pipe(
                retry(1));
    }

    /**
     * Metodo para eliminar un adjunto asociada al informe en borrador dado su id
     * @param id Id del adjunto
     * @returns Resultado de la operación
     */
    public srvEliminarAdjuntoInformeBorrador(id): Observable<TrnRespuestaServicioRpt> {
        const url = this.END_POINT + '/CtoInformeContratista/eliminarAdjuntoInformeBorrador/' + id;
        return this.http.delete(url)
            .pipe(
                retry(1));
    }

    /**
     * Metodo para eliminar un adjunto asociada al informe definitivo dado su id
     * @param id Id del adjunto
     * @returns Resultado de la operación
     */
    public srvEliminarAdjuntoInforme(id): Observable<TrnRespuestaServicioRpt> {
        const url = this.END_POINT + '/CtoInformeContratista/eliminarAdjuntoInforme/' + id;
        return this.http.delete(url)
            .pipe(
                retry(1));
    }

    /**
     * Metodo para guardar la información de Planilla, Trabajadores y actualizar la información tributaria (esResponsableIva, estaObligadoFacturar) para un informe en Borrador
     * @param informeContratista Objeto de tipo CtoInformeContratistaDTO
     * @returns Resultado de la operación
     */
    public srvGuardarBeneficiosTributariosBorrador(informeContratista: CtoInformeContratistaDTO): Observable<TrnRespuestaServicioRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarBeneficiosTributariosBorrador', informeContratista)
            .pipe(
                retry(1));
    }

    public srvGuardarAnexoInformeBorrador(data) {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarAnexoInformeBorrador', data)
            .pipe(
                retry(1));
    }

    public srvGuardarAnexoInforme(data) {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarAnexoInforme', data)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que llamar al servicio de para guardar factura borrador y actualizar las variables de responsableIva y ObligadoFacturar
     * @param informeContratista Objeto de tipo CtoInformeContratistaDTO
     * @returns Resultado de la operacion de tipo TrnRespuestaServicioRpt
     */
    public srvGuardarFacturaBorrador(informeContratista: CtoInformeContratistaDTO): Observable<TrnRespuestaServicioRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarFacturaBorrador', informeContratista)
            .pipe();
    }

    /**
     * Metodo que llamar al servicio de para guardar factura de un informe definitivo y actualizar las variables de responsableIva y ObligadoFacturar
     * @param informeContratista Objeto de tipo CtoInformeContratistaDTO
     * @returns Resultado de la operacion de tipo TrnRespuestaServicioRpt
     */
    public srvGuardarFactura(informeContratista: CtoInformeContratistaDTO): Observable<TrnRespuestaServicioRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarFactura', informeContratista)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que llama al servicio para publicar un informe en borrador y enviarlo a revisión de parte del vobo/supervisor como aplique
     * @param idInforme Id del informe
     * @returns Resultado de la operación 
     */
    public publicarInforme(idInforme): Observable<RpUspCtoFinalizaInformeContratistaBorrador> {
        return this.http.post<RpUspCtoFinalizaInformeContratistaBorrador>(this.END_POINT + '/CtoInformeContratista/publicarInforme/' + idInforme, idInforme)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
       * Metodo para guardar las actividades de las obligaciones contractuales para el informe definitivo
       * @param data Objeto de tipo CtoInformeContratistaObligacionesDTO
       * @returns Resultado de la operación
       */
    public srvGuardarActividadObligacion(data): Observable<TrnRespuestaServicioRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarActividadObligacion', data)
            .pipe(
                retry(1));
    }

    /**
     * Metodo para guardar la información de Planilla, Trabajadores y actualizar la información tributaria (esResponsableIva, estaObligadoFacturar) para un informe definitivo
     * @param data Objeto de tipo CtoInformeContratistaDTO
     * @returns Resultado de la operación
     */
    public srvGuardarBeneficiosTributarios(informeContratista: CtoInformeContratistaDTO): Observable<TrnRespuestaServicioRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/guardarBeneficiosTributarios', informeContratista)
            .pipe(
                retry(1));
    }

    /**
     * Metodo para consultar las observaciones de un informe definitivo cuando ha sido revisado por los involucrados
     * @param idInforme Id del informe
     * @param version Número de la version del informe por defecto es 0
     * @returns Resultado de la operación
     */
    public srvGetObservacionesInforme(idInforme, version): Observable<CtoInformeObservacionRptDTO> {
        return this.http.get(this.END_POINT + '/CtoInformeContratista/getObservacionesInforme/' + idInforme + '/' + version)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que llama al servicio para crear una versión del informe cuando es rechazado por un vobo/supervisor
     * @param idInforme Id del informe
     * @param version Numero de versión
     * @returns Resultado de la operación 
     */
    public guardarNuevaVersionInforme(idInforme, version): Observable<TrnRespuestaServicioRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/nuevaVersionInforme/' + idInforme + '/' + version, idInforme)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que llama al servicio para enviar al informe a revisión de parte del vobo/supervisor como aplique
     * @param idInforme Id del informe
     * @param version Numero de versión
     * @returns Resultado de la operación 
     */
    public srvEnviarInforme(idInforme, version): Observable<CtoInformeContratistaEnviarInformeRpt> {
        return this.http.post(this.END_POINT + '/CtoInformeContratista/enviarInforme/' + idInforme + '/' + version, idInforme)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para copiar un informe cuando es Anulado por Central de pagos es devcir que el informe Anulado queda con la informacion guardada y la nueva versión se trabaja con un nuevo informe diferente
     * @param idInforme Id del informe
     * @returns Resultado de la operación
     */
    public srvCopiarInforme(idInforme): Observable<CtoInformeContratistaCopiarInformeRpt> {
        return this.http.post<any>(this.END_POINT + '/CtoInformeContratista/crearCopiaInforme/' + idInforme, idInforme)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar el listado paramterico de CtoMaTipoDeduccionDeclaracionRenta     
     * @returns Resultado de la operación con el listado de tipos de deducción de la declaración de renta 
     */
    public srvGetCtoMaTipoDeduccionDeclaracionRenta(): Observable<CtoMaTipoDeduccionDeclaracionRentaRptDTO> {
        return this.http.get<CtoMaTipoDeduccionDeclaracionRentaRptDTO>(this.END_POINT + '/CtoMaTipoDeduccionDeclaracionRenta/GetCtoMaTipoDeduccionDeclaracionRenta/')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
}
